// import Libraries
import React, { useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { CardElement, useElements, useStripe, AddressElement, } from "@stripe/react-stripe-js";
import Swal from "sweetalert2";
import { useLocation, useNavigate } from "react-router-dom";
import subScriptionAPIObject from "../../helper/api/subscribtion.api";
import PulseLoader from "react-spinners/PulseLoader";
import './payment.css'

// client secret key
// const stripePromise = loadStripe("pk_test_51NWnM2AyBCL6pDEBzdxvFfLNFhbEER1jL0Xp4ugA1GYPcA8P67hiMkG6OdBFOAk5Ru6xT3WemDgh7h0wWABUPCv700IkWvlIxZ");

// client secret live key
const stripePromise = loadStripe("pk_live_51NWnM2AyBCL6pDEBMx4DeoslUqqe2mwsE2dFEYFRtebzsIJ43L1kgrZ3VxBbXYltlNdp75EtiJNyamzD87QErKiH00IDsif5GU");

// vishal secret key
// const stripePromise = loadStripe("pk_test_51Naf7aHeZSkkFM9T8MfL78spLXoZtAeBOhdj0ZDiDDNkcTQB9AUeFiYKQASpBRkVAHDy6bEOR4uYsoRsKJER3fDb00C2ossfTw");


const ErrorPage = () => {
    return (
        <div style={{ textAlign: 'center', alignItems: 'center', marginTop: '100px' }}>
            <h1 style={{ fontSize: "10rem" }} >404</h1>
            <h2 style={{ margin: "20px auto" }} >Sorry, page not found</h2>
            <h3>The link you followed probably broken or the page has been removed.</h3>
        </div>
    )
}

function Payment() {
    const location = useLocation();
    let { planId, currentPlan, currentPlanId } = location.state;

    return (
        <>
            {
                location?.state
                    ?
                    <>
                        {
                            <div className="FWH">
                                <h1 style={{
                                    textAlign: "center",
                                    marginBottom: '40px', fontSize: '32px', 'color': '#1D3250'
                                }}>Fill your payment details</h1>
                                <div>
                                    <Elements stripe={stripePromise}>
                                        <Checkout planId={planId} currentPlan={currentPlan} currentPlanId={currentPlanId} />
                                    </Elements>
                                </div>
                            </div>}
                    </>
                    :
                    <ErrorPage />
            }
        </>
    );
}

export default Payment;

export function Checkout({ planId, currentPlan, currentPlanId }) {

    const navigate = useNavigate();
    const stripe = useStripe();
    const elements = useElements();
    const [address, setaddress] = useState({});
    const [name, setName] = useState("");
    const [loderButton, setLoderButton] = useState(false);


    const CARD_OPTIONS = {
        appearance: {
            /*...*/
        },
        iconStyle: "solid",
        style: {
            base: {
                iconColor: " #1D3250",
                color: " #1D3250",
                fontWeight: 500,
                fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
                fontSize: "14px",
                fontSmoothing: "antialiased",
                ":-webkit-autofill": { color: " #1D3250" },
                "::placeholder": { color: " #1D3250" },
            },
            invalid: {
                iconColor: "#ffc7ee",
                color: " #1D3250",
            },
        },
    };

    const override = {
        color: "#fff",
        loading: true,
    };

    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent the default form submission
        setLoderButton(true);
        const cardElement = elements.getElement(CardElement);
        const { error } = await stripe.createPaymentMethod({ type: 'card', card: cardElement });
        if (
            name === undefined ||
            address.line1 === undefined ||
            address.city === undefined ||
            address.state === undefined ||
            address.postal_code === undefined ||
            address.country === undefined
        ) {
            setLoderButton(false);
            Swal.fire({
                icon: "error",
                title: "",
                text: "All feilds must be required",
                timer: 2500,
                showConfirmButton: false,
            });
        } else if (cardElement._empty) {
            setLoderButton(false);
            Swal.fire({
                icon: "error",
                title: "",
                text: "Please enter card details",
                timer: 2500,
                showConfirmButton: false,
            });
        } else if (error) {
            setLoderButton(false);
            Swal.fire({
                icon: "error",
                title: "",
                text: "Invalid card details",
                timer: 2500,
                showConfirmButton: false,
            });
            return;
        } else {
            try {
                e.preventDefault();
                if (!elements || !stripe) return;
                const cardElement = elements.getElement(CardElement);
                let token = await stripe.createToken(cardElement, {
                    name: name,
                    address_line1: address.line1,
                    address_line2: address.line2 == null ? "" : address.line2,
                    address_city: address.city,
                    address_state: address.state,
                    address_zip: address.postal_code,
                    address_country: address.country,
                });

                const body = {
                    cardToken: token.token.id,
                    planId
                };

                let authToken = localStorage.getItem('auth-token')

                if (currentPlan) {
                    subScriptionAPIObject.cancelSubscription(authToken, currentPlanId, (res) => {
                        if (res?.data?.status === false) {
                            setLoderButton(false);
                            if (res?.data?.msg === "Invalid link" || res?.data?.msg === 'Link expired') {
                                Swal.fire({
                                    icon: "info",
                                    title: "",
                                    text: "Logged in session has expired. Please log in again",
                                    timer: 2500,
                                    showConfirmButton: false,
                                });
                                localStorage.clear();
                                window.location.reload(true)
                                return navigate('/auth/login')
                            }
                            return Swal.fire({
                                icon: "error",
                                title: "",
                                text: "Payment failed!, please try again.",
                                timer: 2500,
                                showConfirmButton: false,
                            });
                        }

                    })
                }

                subScriptionAPIObject.subscription(authToken, body, async (res) => {

                    if (res?.data?.status) {
                        if (res.data?.actionRequired) {
                            const { paymentIntent, error } = await stripe.confirmCardPayment(res.data.clientSecret);
                            if (error) {
                                setLoderButton(false);
                                return Swal.fire({
                                    icon: "error",
                                    title: "",
                                    text: "Payment failed!, please try again.",
                                    timer: 2500,
                                    showConfirmButton: false,
                                });
                            }

                            if (paymentIntent.status === "succeeded") {
                                subScriptionAPIObject.check3Dsecure(authToken, res.data.id, (responseData) => {
                                    if (responseData?.data?.status) {
                                        setLoderButton(false);
                                        Swal.fire({
                                            icon: "success",
                                            title: "",
                                            text: `Payment successful  ${res.data.id}`,
                                            timer: 2500,
                                            showConfirmButton: false,
                                        });
                                        return navigate('/')
                                    }
                                    setLoderButton(false);
                                    return Swal.fire({
                                        icon: "error",
                                        title: "",
                                        text: `Something went wrong!`,
                                        timer: 2500,
                                        showConfirmButton: false,
                                    });
                                })
                            }
                        } else {
                            setLoderButton(false);
                            Swal.fire({
                                icon: "success",
                                title: "",
                                text: "Payment successful",
                                timer: 2500,
                                showConfirmButton: false,
                            });
                            return navigate('/home')

                        }

                    } else {
                        setLoderButton(false);
                        if (res?.data?.msg === "Invalid link" || res?.data?.msg === 'Link expired') {
                            Swal.fire({
                                icon: "info",
                                title: "",
                                text: "Logged in session has expired. Please log in again",
                                timer: 2500,
                                showConfirmButton: false,
                            });
                            localStorage.clear();
                            window.location.reload(true)
                            return navigate('/auth/login')
                        }
                        return Swal.fire({
                            icon: "error",
                            title: "",
                            text: "Payment failed!, please try again.",
                            timer: 2500,
                            showConfirmButton: false,
                        });
                    }
                })
            } catch (error) {
                setLoderButton(false);
                Swal.fire({
                    icon: "error",
                    title: "",
                    timer: 2500,
                    text: "Payment failed!",
                    showConfirmButton: false,
                });
            }
        }
    };
    return (
        <form style={{ width: "100%" }} onSubmit={handleSubmit}>
            <fieldset
                style={{
                    color: " #1D3250 !important",
                    border: "0.5px solid  #1D3250",
                    borderRadius: "10px",
                    marginBottom: "3%",
                    fontSize: "12px !important",
                }}
            >
                <div className="FormRow">
                    <CardElement options={CARD_OPTIONS} />
                </div>
            </fieldset>
            <fieldset
                style={{
                    border: "0.5px solid  #1D3250",
                    borderRadius: "10px",
                    fontSize: "12px",
                }}
            >
                <AddressElement
                    options={{
                        mode: "billing",
                    }}
                    onChange={(event) => {
                        if (event.complete) {
                            const address = event.value.address;
                            setName(event.value.name)
                            setaddress(address);
                        }
                    }}
                />
            </fieldset>
            <fieldset
                style={{
                    color: " #1D3250",
                    border: " #1D3250",
                }}
            ></fieldset>
            {/* <button className='sendcode-button' disabled={loderButton}
            >
                {
                    loderButton ? (

                        "Loading..."

                    ) : (
                        "Confirm and Pay"
                    )
                }
            </button> */}
            <button className='sendcode-button' disabled={loderButton}
            >
                {
                    loderButton ? (
                        <div>
                            <PulseLoader
                                title="loading"
                                style={{ paddingBottom: '0px' }}
                                color={override.color}
                                loading={override.loading}
                                cssOverride={override}
                                size={10}
                                aria-label="Loading Spinner"
                                data-testid="loader"
                            />
                        </div>
                    ) : (
                        "Confirm and Pay"
                    )
                }


            </button>
        </form>
    );
}

// sk_test_51NWnM2AyBCL6pDEBGgQCI4OQdLFJjDagMziQiSGQDKGXWLD4j3Kwz38hTkNAsnf0U2bLHzg6AwVGv8tSTSvwMs9a00rBMswXwT