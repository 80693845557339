import React from 'react';
import { NavLink, useNavigate } from "react-router-dom";
import MenuIcon from '@mui/icons-material/Menu';
import "./sidebar.css";
import Swal from 'sweetalert2';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import AssignmentIcon from '@mui/icons-material/Assignment';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import LogoutIcon from '@mui/icons-material/Logout';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

const ICON_SIZE = 20;
function Sidebar({ visible, show, name }) {
    const navigate = useNavigate()

    const logout = async () => {
        const result = await Swal.fire({
            icon: "question",
            title: 'Are you sure want to logout?',
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Yes',
            denyButtonText: `No`,
        })
        if (result.isConfirmed) {
            localStorage.removeItem("auth-token");
            // window.location.reload(true)
            navigate("/");
        }
    };

    return (
        <>
            <div className="mobile-nav">
                <button
                    className="mobile-nav-btn"
                    onClick={() => show(!visible)}
                >
                    <MenuIcon size={24} />
                </button>
            </div>
            <nav className={!visible ? 'sideNav navbar' : 'sideNav'}>
                <button
                    type="button"
                    className="nav-btn"
                    onClick={() => show(!visible)}
                >
                    {!visible ? <ArrowForwardIosIcon size={30} /> : <ArrowBackIosNewIcon size={30} />}
                </button>
                <div>
                    <h1 className='sideBarHeading' >JOBGURU</h1>
                    <div className="sidebarImg">
                        <h1>{name?.substring(0, 1).toUpperCase()}</h1>
                    </div>
                    <div className="links nav-top">
                        <NavLink to="/home/" className={({ isActive }) => isActive ? "active nav-link" : 'nav-link'} >
                            <MenuBookIcon size={ICON_SIZE} />
                            <span>Job interview preparation</span>
                        </NavLink>
                        <NavLink to="/home/taskgenration" className={({ isActive }) => isActive ? "active nav-link" : 'nav-link'} >
                            <AssignmentIcon size={ICON_SIZE} />
                            <span>Task generation</span>
                        </NavLink>
                        <NavLink to="/home/pricing" className={({ isActive }) => isActive ? "active nav-link" : 'nav-link'} >
                            <SubscriptionsIcon size={ICON_SIZE} />
                            <span>Pricing</span>
                        </NavLink>

                        <NavLink to="/home/billing" className={({ isActive }) => isActive ? "active nav-link" : 'nav-link'} >
                            <AccountBalanceWalletIcon size={ICON_SIZE} />
                            <span>Billing & Subscription</span>
                        </NavLink>

                        <NavLink to="/home/userdetails" className={({ isActive }) => isActive ? "active nav-link" : 'nav-link'} >
                            <AccountBoxIcon size={ICON_SIZE} />
                            <span>My account</span>
                        </NavLink>
                        <label onClick={() => { logout() }} className='nav-link' >
                            <LogoutIcon size={ICON_SIZE} />
                            <span>Logout</span>
                        </label>
                    </div>
                </div>
            </nav>
        </>
    );
}

export default Sidebar;


// import { NavLink, useNavigate } from 'react-router-dom'
// import Swal from "sweetalert2";
// import './sidebar.css'

// export default function Sidebar({ name }) {

//     const navigate = useNavigate()

//     const logout = async () => {
//         const result = await Swal.fire({
//             title: 'Are you sure want to logout?',
//             showDenyButton: true,
//             showCancelButton: false,
//             confirmButtonText: 'Yes',
//             denyButtonText: `No`,
//         })

//         if (result.isConfirmed) {
//             localStorage.removeItem("auth-token");
//             window.location.reload(true)
//             navigate("/");
//         }

//     };
//     return (
//         <div className='sidebar' >
//             <div>
//                 <h1 className="sidebarImg" >{name?.substring(0, 1).toUpperCase()}</h1>
//             </div>

//             <div className='sidebarWrappers'>
//                 <ul className="sidebarList">
//                     <NavLink to="/home/home/userdetails" className={({ isActive }) => isActive ? "active nav-link" : 'sidebarItem'}  >
//                         <span className='sidebarItemText' >My account</span>
//                         <div className='sidebar-line'></div>
//                     </NavLink>
//                     <NavLink to="/home/home/billing" className={({ isActive }) => isActive ? "active nav-link" : 'sidebarItem'}  >
//                         <span className='sidebarItemText' >Billing & susbcription</span>
//                         <div className='sidebar-line'></div>
//                     </NavLink>
//                     <li className="sidebarItem">
//                         <span className='sidebarItemText' onClick={() => { logout() }}>Logout</span>
//                         <div className='sidebar-line'></div>
//                     </li>
//                 </ul>
//             </div>

//         </div>
//     )
// }