import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import './billing.css';
import Swal from 'sweetalert2';
import subScriptionAPIObject from '../../helper/api/subscribtion.api';

export default function Billing() {

    const navigate = useNavigate()
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("No");
    const [plan, setPlan] = useState("");
    const [currentPlanId, setCurrentPlanId] = useState("");
    const [price, setPrice] = useState("    ");
    const [loderButton, setLoderButton] = useState(false);
    const [isActivePlan, setIsActivePlan] = useState(false);
    const [errorMessage, setErrorMessage] = useState("")

    useEffect(() => {
        GetActivePlan()
    }, [])

    let freePlan = "free_trial_id"
    // let starterMonthlyPlanId = "price_1NavZlHeZSkkFM9TUotUsCcr"
    // let professionalMonthlyPlanId = 'price_1NbO2IHeZSkkFM9TPlZX26Ly'
    let starterMonthlyPlanId = "price_1NdrqDAyBCL6pDEBFN8DNRxS"
    let professionalMonthlyPlanId = 'price_1NdrytAyBCL6pDEBTOydFbOw'
    let premiumMonthlyPlanId = 'price_1Nds0iAyBCL6pDEB6qhVv8jy'

    let starterYearlyPlanId = 'price_1Nds6aAyBCL6pDEBcRJQhFrl'
    let professionalYearlyPlanId = 'price_1Nds8bAyBCL6pDEBanUzfsWv'
    let premiumYearlyPlanId = 'price_1NdsAKAyBCL6pDEBM9yB5p3R'

    const GetActivePlan = () => {
        const authToken = localStorage.getItem('auth-token')
        return subScriptionAPIObject.getValidPlan(authToken, async (result) => {
            if (result?.data?.status) {
                setIsActivePlan(result.data?.activePlan)
                setStartDate(result.data.data.planStartDate)
                setEndDate(result.data.data.planEndDate)
                setCurrentPlanId(result.data.data.planId)
                if (result.data.data.planId === starterMonthlyPlanId) {
                    setPrice('$15/month')
                    setPlan('Starter')
                }
                if (result.data.data.planId === professionalMonthlyPlanId) {
                    setPrice('$20/month')
                    setPlan('Professional')
                }
                if (result.data.data.planId === premiumMonthlyPlanId) {
                    setPrice('$25/month')
                    setPlan('Premium')
                }
                if (result.data.data.planId === starterYearlyPlanId) {
                    setPrice('$126/year')
                    setPlan('Starter')
                }
                if (result.data.data.planId === professionalYearlyPlanId) {
                    setPrice('$168/year ')
                    setPlan('Professional')
                }
                if (result.data.data.planId === premiumYearlyPlanId) {
                    setPrice('$210/year')
                    setPlan('Premium')
                }
                if (result.data.data.planId === freePlan) {
                    setPrice('$0/month')
                    setPlan('Free')
                }
            } else {
                if (result?.data?.msg === "Invalid link" || result?.data?.msg === 'Link expired') {
                    Swal.fire({
                        icon: "info",
                        title: "",
                        text: "Logged in session has expired. Please log in again",
                        timer: 2500,
                        showConfirmButton: false,
                    });
                    localStorage.clear();
                    window.location.reload(true)
                    return navigate('/auth/login')
                }
                setErrorMessage(result?.data.msg)
            }

        })
    }

    const CancelSubscription = async () => {
        const response = await Swal.fire({
            title: 'Do you want to the cancel subscribed Tier?',
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: 'Yes',
            denyButtonText: `No`,
        })

        if (!response?.isConfirmed) {
            return null;
        }
        setLoderButton(true);
        let authToken = localStorage.getItem('auth-token')
        subScriptionAPIObject.cancelSubscription(authToken, currentPlanId, (res) => {
            if (res?.data?.status) {
                setLoderButton(false);
                Swal.fire({
                    icon: "success",
                    title: "",
                    text: "Subscription Canceled successfully.",
                    timer: 2500,
                    showConfirmButton: false,
                });
                return GetActivePlan();
            } else {
                setLoderButton(false);
                GetActivePlan();

                if (res?.data?.msg === "Invalid link" || res?.data?.msg === 'Link expired') {
                    Swal.fire({
                        icon: "info",
                        title: "",
                        text: "Logged in session has expired. Please log in again",
                        timer: 2500,
                        showConfirmButton: false,
                    });
                    localStorage.clear();
                    window.location.reload(true)
                    return navigate('/auth/login')
                }


                return Swal.fire({
                    icon: "error",
                    title: "",
                    text: "Something went wrong!, please try again.",
                    timer: 2500,
                    showConfirmButton: false,
                });
            }
        })
    }

    return (
        <div className='userDetailsContainer'>
            <div className='homeHeading'>
                <div className='head-div'>
                    <h1>Billing & Subscription</h1>
                    {/* <Link to="/"> <ExitToAppIcon className='backIcon' /></Link> */}
                </div>

                {/* <div className='homeHeadingLine'></div> */}
            </div>
            <div className='detailsChildContainer'>

                {
                    errorMessage
                        ?
                        <div className="inputList">
                            <p style={{ margin: '20px auto', color: '#BE7435', fontSize: '20px' }}>{errorMessage}</p>
                            < div className="subbmit-button">
                                <button
                                    type="button" onClick={() => navigate('/home/pricing')} >
                                    Pricing
                                </button>
                            </div>

                        </div>
                        :

                        <div className="inputList">
                            <div className='edit-profile'>
                                <span>Current Subscription</span>
                            </div>

                            <div className="subscribeItem">
                                <span className='subscribespan'>Plan:</span>
                                <h1 className='subscibeH1'> {plan}</h1>
                            </div>
                            <div className="subscribeItem">
                                <span className='subscribespan'>Start Date:</span>
                                <h1 className='subscibeH1'> {startDate}</h1>

                            </div>
                            <div className="subscribeItem">
                                <span className='subscribespan'>End Date:</span>
                                <h1 className='subscibeH1'> {endDate}</h1>

                            </div>

                            <div className="subscribeItem">
                                <span className='subscribespan'>Price:</span>
                                <h1 className='subscibeH1'> {price}</h1>

                            </div>
                            {!isActivePlan && <p style={{ marginTop: '10px', color: '#BE7435' }}>The current tier has been <b>unsubscribed</b>. Please proceed to the pricing section to select the subscription tier that best suits your needs. </p>}




                            {loderButton
                                ?
                                <div className='subbmit-button'>
                                    <Box sx={{ alignItems: "center" }}>
                                        <CircularProgress sx={{ color: " #1D3250", size: "10px" }} />
                                    </Box>
                                </div>
                                :

                                isActivePlan
                                    ?
                                    < div className="subbmit-button">
                                        <button type="button" onClick={() => CancelSubscription()}>
                                            Cancel subscription </button>
                                    </div>
                                    :

                                    < div className="subbmit-button">
                                        <button type="button" onClick={() => navigate('/home/pricing')} >
                                            Manage subscription </button>
                                    </div>

                            }
                        </div>
                }
            </div>

        </div >
    )
}
