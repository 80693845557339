import axios from "axios";
import config from "../../../config/config";

let authAPIObject = {
  register: async (data, callback) => {
    axios({
      method: "POST",
      url: `${config.API_URL}/auth/register`,
      data: data,
    })
      .then((response) => {
        return callback(response);
      })
      .catch((err) => {
        return callback(err.response);
      });
  },
  login: async (data, callback) => {
    axios({
      method: "POST",
      url: `${config.API_URL}/auth/login`,
      data: data,
    })
      .then((response) => {
        return callback(response);
      })
      .catch((err) => {
        return callback(err.response);
      });
  },
  emailVerify: async (token, callback) => {
    axios({
      method: "GET",
      url: `${config.API_URL}/auth/emailVerify`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        return callback(response);
      })
      .catch((err) => {
        return callback(err.response);
      });
  },
  forgetemailverify: async (token, callback) => {
    axios({
      method: "GET",
      url: `${config.API_URL}/auth/forgetemailverify`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        return callback(response);
      })
      .catch((err) => {
        return callback(err.response);
      });
  },
  updatePassword: async (data, callback) => {
    axios({
      method: "PUT",
      url: `${config.API_URL}/auth/updatepassword`,
      data: data,
    })
      .then((response) => {
        return callback(response);
      })
      .catch((err) => {
        return callback(err.response);
      });
  },
  foregetPassword: async (email, callback) => {
    axios({
      method: "GET",
      url: `${config.API_URL}/auth/forgetpassword?email=${email}`,
    })
      .then((response) => {
        return callback(response);
      })
      .catch((err) => {
        return callback(err.response);
      });
  },
  getUser: async (data, callback) => {
    axios({
      method: "POST",
      url: `${config.API_URL}/auth/get`,
      data: data
    })
      .then((response) => {
        return callback(response);
      })
      .catch((err) => {
        return callback(err.response);
      });
  },
  sendEmail: async (data, callback) => {
    axios({
      method: "POST",
      url: `${config.API_URL}/auth/sentemailverification`,
      data: data,
    })
      .then((response) => {
        console.log("ressssssssssss", response)
        return callback(response);
      })
      .catch((err) => {
        return callback(err.response);
      });
  },
};

export default authAPIObject;
