import React from "react";
import { useNavigate } from "react-router-dom";
import "./dashboard.css";

const Dashboard = () => {
  const navigate = useNavigate();

  return (
    <div>
      <section className="upcoming">
        <div className="upcoming-content">
          <div className="upcoming-title">
            <h1>JobGuru</h1>
            <h2> Your Path to Professional Success</h2>
            <p>
              Unlock your potential with Jobguru. From tailored interview preparations based on job descriptions to generating actionable sub-tasks within your set duration, our platform ensures your preparation is top-notch. Plus, delve deeper into your documents with our chat-to-document feature, allowing you to inquire about any uploaded PDF. Elevate your career journey with Jobguru, bridging the gap between opportunities and talent.
            </p>
            {/* <a href="#" className="ctn" >Learn More</a> */}
          </div>
          <div className="upcoming-images">
            <img src="./assets/leptop.png" alt="img1" />
          </div>
        </div>
      </section>

      <section className="events">
        <div className="title">
          <h1>How can I be of assistance?</h1>
        </div>
        <div className="row">
          <div className="col">
            <button className="task" onClick={() => { navigate("/home/taskgenration") }}>
              Task Generation Assistant
            </button>
          </div>
          <div className="col">
            <button
              className="preparation"
              onClick={() => navigate("/home/")}
            >
              Job Interview Preparation Assistant
            </button>
          </div>
        </div>
      </section>

      {/* <section className="rating-card">
        <div className="trust-div">
          <div className="trust-heading">
            <h1>Trusted by</h1>
          </div>
          <div className="trust-img">
            <img src="./assets/company.png" alt="loading.." />
            <img src="./assets/company.png" alt="loading.." />
            <img src="./assets/company.png" alt="loading.." />
            <img src="./assets/company.png" alt="loading.." />
          </div>
        </div>
        <div className="rating-div">
          <div className="review-div">
            <div className="review-col">
              <h1>4.8</h1>
              <img src="./assets/rating.png" alt="loading.." />
              <h6 className="rating-value">2,369 Ratings</h6>
              <h6 className="rating-review" >Google Reviews</h6>
            </div>
            <div className="review-col">
              <h1>A+</h1>
              <img src="./assets/rating.png" alt="loading.." />
              <h6 className="rating-value" >125 Ratings</h6>
              <h6 className="rating-review" >Google Reviews</h6>
            </div>
          </div>
          <div className="text-about">
            <h1>Trusted by numerous Job seekers</h1>
            <h4>Ibrahim E</h4>
            <p>
              Our platform stands as a top choice for professionals seeking ideal roles. With our dedicated services and user-friendly interface, finding your next job has never been easier. Join the community that trusts us.
            </p>
          </div>
        </div>
      </section> */}

      <section className="features-Benefits">
        <div className="heading">
          {/* <label></label> */}
          <div className="line"></div>
          <p>Features & Benefits</p>
          <div className="line"></div>
          {/* <label></label> */}
        </div>
        <div className="features-row">
          <div className="features-col">
            <h1>Features</h1>
            <ul>
              <li>
                Extensive job database with thousands of listings from various
                industries and sectors
              </li>
              <li>
                Advanced search filters to refine job search based on location,
                salary, experience, and more
              </li>
              <li>
                User-friendly interface for easy navigation and efficient job
                browsing
              </li>
              <li>
                Personalized job recommendations based on user preferences and
                past search history
              </li>
              <li>
                Unlock tailored interview prep, automated task breakdowns, and interactive document queries with our platform.
              </li>
            </ul>
          </div>
          <div className="features-col">
            <img src="./assets/benefit.png" alt="loading..." />
          </div>
          <div className="features-col">
            <h1>Benefit</h1>
            <ul>
              <li>
                Extensive network of reputable employers: Our job seeking
                company has established connections with a wide range.
              </li>
              <li>
                known and respected companies across various industries,
                providing our users with access to a vast pool of potential job
                opportunities.
              </li>
              <li>
                Our platform utilizes advanced algorithms to match your skills and preferences with suitable job listings, increasing the likelihood of finding your dream job.
              </li>
            </ul>
          </div>

        </div>
      </section>

      <section className="happy">
        <div className="happy-content">
          <div className="happy-images">
            <img src="./assets/happy.png" alt="img1" />
          </div>
          <div className="happy-title">
            <h1>Happy Customers</h1>
            <p>
              We are dedicated to delivering unparalleled support and guidance every step of the way during the job-seeking process, guaranteeing our customers' success and cultivating a lasting bond of trust. Our commitment to your journey extends beyond merely finding employment; we are here to empower you, elevate your career, and stand by your side as you achieve your goals.
            </p>
            {/* <button onClick={() => navigate("/")}>View all testimonial</button> */}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Dashboard;
