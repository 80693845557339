import React, { useEffect, useState } from 'react'
import PlanCard from '../../planCard/planCard';
import Navbar from '../../navbar/navbar';
import Footer from '../../footer/footer';
import './pricing.css';

export default function Pricing() {
    const [freeLoder, setFreeLoder] = useState(false);
    const [basicLoder, setBasicLoder] = useState(false);
    const [standardLoder, setStandardLoder] = useState(false);
    const [premiumLoder, setPremimumLoder] = useState(false);
    const [togle, setTogle] = useState(false);

    // let starterMonthlyPlanId = "price_1NavZlHeZSkkFM9TUotUsCcr"
    // let professionalMonthlyPlanId = 'price_1NbO2IHeZSkkFM9TPlZX26Ly'
    let starterMonthlyPlanId = "price_1NdrqDAyBCL6pDEBFN8DNRxS";
    let professionalMonthlyPlanId = 'price_1NdrytAyBCL6pDEBTOydFbOw';
    let premiumMonthlyPlanId = 'price_1Nds0iAyBCL6pDEB6qhVv8jy';

    let starterYearlyPlanId = 'price_1Nds6aAyBCL6pDEBcRJQhFrl';
    let professionalYearlyPlanId = 'price_1Nds8bAyBCL6pDEBanUzfsWv';
    let premiumYearlyPlanId = 'price_1NdsAKAyBCL6pDEBM9yB5p3R';

    const [starterPrice, setStarterPrice] = useState('$15');
    const [professionalPrice, setProfessionalPrice] = useState('$20');
    const [premiumPrice, setPremiumPrice] = useState('$25');

    const [starterInfrom, setStarterInfrom] = useState(`Billed $15 (excl. VAT) monthly`);
    const [professionalInfrom, setProfessionalInfrom] = useState(`Billed $20 (excl. VAT) monthly`);
    const [premiumInfrom, setPremiumInfrom] = useState(`Billed $25 (excl. VAT) monthly`);

    const [starterPlanId, setStarterPlanId] = useState(starterMonthlyPlanId);
    const [professionalPlanId, setProfessionalPlanId] = useState(professionalMonthlyPlanId);
    const [premiumPlanId, setPremiumPlanId] = useState(premiumMonthlyPlanId);

    useEffect(() => {
        if (togle) {
            setStarterPrice('$10.50')
            setProfessionalPrice('$14')
            setPremiumPrice('$17.50')
            setStarterInfrom('Billed $126 (excl. VAT) annually')
            setProfessionalInfrom('Billed $168 (excl. VAT) annually')
            setPremiumInfrom('Billed $210 (excl. VAT) annually')
            setStarterPlanId(starterYearlyPlanId)
            setProfessionalPlanId(professionalYearlyPlanId)
            setPremiumPlanId(premiumYearlyPlanId)
        } else {
            setStarterPrice('$15')
            setProfessionalPrice('$20')
            setPremiumPrice('$25')
            setStarterInfrom('Billed $15 (excl. VAT) monthly')
            setProfessionalInfrom('Billed $20 (excl. VAT) monthly')
            setPremiumInfrom('Billed $25 (excl. VAT) monthly')
            setStarterPlanId(starterMonthlyPlanId)
            setProfessionalPlanId(professionalMonthlyPlanId)
            setPremiumPlanId(premiumMonthlyPlanId)
        }

    }, [togle])


    //  As far as prices go Here they are:  Free,  Starter $15 per month or $10.50 if paid yearly  , Professional $20 per month or $14 per month yearly   , Premium  $25 per month or $17.50 per month yearly
    // there is a 30% off across the board if paid annually.

    let FreePlan = {
        PlanType: 'Free',
        buttonText: 'Try Now',
        Price: `$0`,
        planId: 'free_trial_id',
        priceInform: `That's just trial period for you!`,
        list: [
            "1 Job Interview Prep sessions",
            "1 Task Generation",
            "1 PDF Uploads per day",
        ]
    }

    let starterPlan = {
        PlanType: 'Starter',
        Price: starterPrice,
        buttonText: 'Subscribe Now',
        planId: starterPlanId,
        priceInform: starterInfrom,
        list: [
            " Unlimited Job Interview Prep sessions",
            "Unlimited Task Generation",
            "3 PDF Uploads per day",
            "Access to AI Chat Support",
        ]
    }

    let professionalPlan = {
        PlanType: 'Professional',
        Price: professionalPrice,
        buttonText: 'Subscribe Now',
        planId: professionalPlanId,
        priceInform: professionalInfrom,
        list: [
            " Unlimited Job Interview Prep sessions",
            "Unlimited Task Generation",
            "5 PDF Uploads per day",
            "Access to AI Chat Support",
        ]
    }

    let PremiumPlan = {
        PlanType: 'Premium',
        Price: premiumPrice,
        buttonText: 'Subscribe Now',
        planId: premiumPlanId,
        priceInform: premiumInfrom,
        list: [
            " Unlimited Job Interview Prep sessions",
            "Unlimited Task Generation",
            "Unlimited PDF Uploads",
            "Access to AI Chat Support",
            "Priority Customer Support",
        ]
    }

    return (
        <>

            <div className='pricingContainer'>
                <div className='priceHeader'>
                    <h1 className='pricingHeading' >Choose your plan</h1>
                    <div className='pricingPay'>
                        <p>Pay monthly</p>
                        <label className="switch">
                            <input type="checkbox" checked={togle} onChange={() => { setTogle(!togle) }} />
                            <span className="slider round"></span>
                        </label>
                        <div>
                            <p>Pay annually</p>
                            <p>and <b className='offer'>save up to 30%</b> of the plan price</p>
                        </div>
                    </div>
                </div>

                <div className='detailsChildContainer'>
                    <div className='planList'>
                        <PlanCard data={FreePlan} lodder={freeLoder} setLodder={setFreeLoder} />
                        <PlanCard data={starterPlan} lodder={basicLoder} setLodder={setBasicLoder} />
                        <PlanCard data={professionalPlan} lodder={standardLoder} setLodder={setStandardLoder} />
                        <PlanCard data={PremiumPlan} lodder={premiumLoder} setLodder={setPremimumLoder} />
                    </div>
                </div>

            </div >
        </>
    )
}
