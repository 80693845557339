// import React, { useEffect, useState } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Dashboard from "../pages/dashboard/dashboard";
import Preparation from "../pages/preparation/preparation";
import Verification from "../pages/auth/verification/verification";
import TaskGenration from "../pages/taskGenration/taskGenration";
import SignUp from "../pages/auth/signup/signup";
import Login from "../pages/auth/login/login";
import SentEmail from "../pages/auth/email/sentEmail";
import About from "../pages/about/about";
import Home from "../pages/home/home";
import UserDetails from "../pages/userDetails/userDetails";
import Billing from "../pages/billing/billing";
import ForgetPassword from "../pages/auth/forgetPassword/forgetPassword";
import UpdatePassword from "../pages/auth/updatePassword/updatePassword";
import SentForgetEmail from "../pages/auth/email/sendForgetEmail";
import Payment from "../pages/payment/payment";
import Pricing from "../pages/pricing/pricing";
import Main from "../pages/main/main";
const Private = ({ Component }) => {
  return localStorage.getItem("auth-token") ? <Component /> : <Navigate to="/auth/login" />;
};

const AppRoutes = () => {
  return (
    <Routes>
      {/* <Route exact path="/user/email/sent" element={<Private Component={SentEmail} />} /> */}
      <Route exact path="/home" element={<Private Component={Home} />} >
        <Route exact index element={<Preparation />} />
        <Route exact path="taskgenration" element={<TaskGenration />} />
        <Route exact path="pricing" element={<Pricing />} />
        <Route exact path="billing" element={<Billing />} />
        <Route exact path="userdetails" element={<UserDetails />} />
        <Route exact path="pricing/payment/4ujduej" element={<Payment />} />
      </Route>

      <Route exact path="/" element={< Main />} >
        <Route exact index element={<Dashboard />} />
        <Route exact path="/about" element={<About />} />
        <Route exact path="/pricing" element={<Pricing />} />
      </Route>

      <Route exact path="/auth/login/" element={localStorage.getItem("auth-token") ? <Navigate to="/home" /> : < Login />} />
      <Route exact path="/auth/signup/" element={localStorage.getItem("auth-token") ? <Navigate to="/home" /> : < SignUp />} />
      <Route exact path="/auth/forgetpassword" element={localStorage.getItem("auth-token") ? <Navigate to="/home" /> : < ForgetPassword />} />
      <Route exact path="/auth/email/updatepassword" element={<UpdatePassword />} />
      <Route exact path="/auth/forget/sent" element={<SentForgetEmail />} />
      <Route exact path="/auth/email/verify/" element={<Verification />} />
      <Route exact path="/user/email/sent" element={<SentEmail/>} />
    </Routes>
  );

  // return (
  //   <Routes>
  //     <Route exact path="/" element={< Dashboard />} />
  //     <Route exact path="/chattopdf" element={<Private Component={ChatToPdf} />} />
  //     <Route exact path="/preparation" element={<Private Component={Preparation} />} />
  //     <Route exact path="/taskgenration" element={<Private Component={TaskGenration} />} />
  //     <Route exact path="/user/email/sent" element={<Private Component={SentEmail} />} />
  //     <Route exact path="/home" element={<Private Component={Home} />} >
  //       <Route exact path="userdetails" element={<UserDetails />} />
  //       <Route exact path="billing" element={<Billing />} />
  //     </Route>
  //     <Route exact path="/about" element={<About />} />
  //     <Route exact path="/pricing" element={<Pricing />} />
  //     <Route exact path="/pricing/payment/4ujduej" element={<Private Component={Payment} />} />

  //     <Route exact path="/auth/login/" element={localStorage.getItem("auth-token") ? <Navigate to="/" /> : < Login />} />
  //     <Route exact path="/auth/signup/" element={localStorage.getItem("auth-token") ? <Navigate to="/" /> : < SignUp />} />
  //     <Route exact path="/auth/forgetpassword" element={localStorage.getItem("auth-token") ? <Navigate to="/" /> : < ForgetPassword />} />
  //     <Route exact path="/auth/email/updatepassword" element={<UpdatePassword />} />
  //     <Route exact path="/auth/forget/sent" element={<SentForgetEmail />} />
  //     <Route exact path="/auth/email/verify/" element={<Verification />} />
  //     <Route path="#" element={<Dashboard />} />
  //   </Routes>
  // );
};
export default AppRoutes;