import { useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";
import AppRoutes from "./components/routes/routes";
import './app.css'

const Wrapper = ({ children }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
};

function App() {
  return (
    <Wrapper>
      <AppRoutes />
    </Wrapper>
  );
}

export default App;