import React, { useEffect, useState, useRef } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { useLocation, useNavigate } from "react-router-dom";
import authAPIObject from "../../../helper/api/auth.api";
import Box from "@mui/material/Box";
import "./sentEmail.css";
import Swal from "sweetalert2";

const SentEmail = () => {
  const isListeningRef = useRef(true);
  const navigate = useNavigate();
  const location = useLocation();

  const [email, setEmail] = useState("");
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(60);
  const [apiResponse, setApiResponse] = useState(true);
  const [msg, setMsg] = useState("");
  const [isEmailVerified, setIsEmailVerified] = useState(false);


  useEffect(() => {
    let email = location.state?.email;
    setEmail(email);

    let apiInterval = setInterval(() => {
      CheckEmailVerifed();
    }, 3000);
    if (isEmailVerified) {
      if (!isListeningRef.current) {
        clearInterval(apiInterval);
        navigate("/home/");
      }
    }

    return () => {
      clearInterval(apiInterval);
      isListeningRef.current = false;
    };

  }, [isEmailVerified, location.state?.email, navigate]);

  const CheckEmailVerifed = () => {
    // const token = localStorage.getItem("auth-token");
    console.log("location.state?.userId", location.state, location.state?.userId)

     authAPIObject.getUser({userId: location.state?.userId}, (result) => {
      if (result?.data?.status === "false" || result?.data?.status === false) {
          Swal.fire({
            icon: "error",
            title: "",
            text: result?.data?.msg,
            timer: 2500,
            showConfirmButton: false,
          });
          localStorage.clear();
          return navigate('/auth/login')
      }
  
      if (result?.data?.data?.data.isEmailVerified) {
        localStorage.setItem("auth-token", result?.data?.data.token);
        setIsEmailVerified(true)
      }
    });
  };

  const ResendEmail = () => {
    // const token = localStorage.getItem("auth-token");
    authAPIObject.sendEmail({ userId: location.state?.userId}, (result) => {
      if (result?.data?.status === "false" || result?.data?.status === false) {
        setApiResponse(false)
        setMsg("Something went wrong. Please send again");

        return setTimeout(() => {
          setMsg("");
        }, 2000);
      }
      setMsg("Email sent successfully.");
      setTimeout(() => {
        setMsg("");
      }, 2000);
      return setSeconds(60);
    });
  };

  useEffect(() => {
    var myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  }, [seconds]);

  return (
    <div className="verify-card">
      <div className="main-card">
        <h1>Verify your email</h1>
        <Box sx={{ alignItems: "center", margin: "15px" }}>
          <CircularProgress sx={{ color: " #1D3250", size: "10px" }} />
        </Box>
        {/* <ErrorOutlineIcon
          sx={{ color: "white", margin: "20px" }}
          fontSize="large"
        /> */}
        <h2 className="verify-text">
          An email has been sent to
          <label style={{ color: " #1D3250" }}> {email}.</label>
        </h2>
        <h2 className="verify-text">please verify your email to continue.</h2>
      </div>

      <div className="resend_email">
        <p>Did not get the email?</p>
        {minutes === 0 && seconds === 0 ? (
          <label style={{ color: '#46413e' }} onClick={() => ResendEmail()}>Resend email</label>
        ) : (
          <>
            <h3 style={{ color: "#46413e" }}>
              Resend mail in {minutes}:{seconds < 10 ? `0${seconds}` : seconds}{" "}
              sec
            </h3>
            {msg && <h1 style={{ color: apiResponse ? '#484848' : '  #1D3250', marginTop: '10px' }}>{msg}</h1>}
          </>
        )}
      </div>

      <div className="bottom-text">
        <p>Self-custodial verify by</p>
        <p>JobGuru.ai</p>
      </div>
    </div>
  );
};

export default SentEmail;
