import React, { useEffect, useState } from 'react'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import CloseIcon from '@mui/icons-material/Close';
import userAPIObject from '../../helper/api/user.api';
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import './userDetails.css';
import { useNavigate, useOutletContext } from 'react-router-dom';
import Swal from 'sweetalert2';

export default function UserDetails() {
    const navigate = useNavigate()
    const [fullName, setFullName] = useState("");
    const [email, setEmail] = useState("");
    const [fullNameError, setFullNameError] = useState("");
    const [lodder, setLodder] = useState("");
    const [message, setMessage] = useState("");
    const [editTogle, setEditTogle] = useState(true);
    const [setName] = useOutletContext();


    useEffect(() => {
        getUser()
    }, [])

    const getUser = () => {
        const token = localStorage.getItem("auth-token");
        userAPIObject.getUser(token, (result) => {
            if (result?.data?.status === "false" || result?.data?.status === false) {
                if (result?.data?.msg === "Invalid link" || result?.data?.msg === 'Link expired') {
                    Swal.fire({
                        icon: "info",
                        title: "",
                        text: "Logged in session has expired. Please log in again",
                        timer: 2500,
                        showConfirmButton: false,
                    });
                    localStorage.clear();
                    window.location.reload(true)
                    return navigate('/auth/login')
                }
                return Swal.fire({
                    icon: "error",
                    title: "",
                    text: result?.data?.msg,
                    timer: 2500,
                    showConfirmButton: false,
                });
            }
            setFullName(result?.data?.data?.fullName)
            setEmail(result?.data?.data?.email)
            setName(result?.data?.data?.fullName)

        });
    }

    const EditFunction = () => {
        if (fullName === "" || fullName === "undefined") {
            setFullNameError("full Name must be required!");
        } else {
            setFullNameError("");
        }

        if (fullName !== "") {
            let token = localStorage.getItem("auth-token");
            setEditTogle(false)
            setLodder(true);
            userAPIObject.updateUser(token,
                { fullName },
                (result) => {
                    setEditTogle(true);
                    setLodder(false);
                    if (result.data.status === "false" || result.data.status === false) {
                        return setMessage(result.data.msg);
                    }
                    setFullName(result?.data?.data?.fullName)
                    setEmail(result?.data?.data?.email)
                    setName(result?.data?.data?.fullName)
                }
            );
        }
    };

    return (
        <div className='userDetailsContainer'>
            <div className='homeHeading'>
                <div className='head-div'>
                    <h1>My account</h1>
                    {/* <Link to="/"> <ExitToAppIcon className='backIcon' /></Link> */}
                </div>
                {/* <div className='homeHeadingLine'></div> */}
            </div>
            <div className='detailsChildContainer'>
                {message && <h6 className="error-msg">{message}</h6>}
                <div className="inputList">
                    <div className='edit-profile'>
                        <span>Edit</span>
                        {
                            editTogle ?
                                <EditOutlinedIcon className='editIcon' onClick={() => { setEditTogle(false) }} />
                                :
                                <CloseIcon className='editIcon'
                                    style={{ color: 'red' }}
                                    onClick={() => { setEditTogle(true) }}
                                />
                        }

                    </div>
                    <div className="inputItem">
                        <label className='inputItemText'>Full name</label>
                        <input
                            type='text'
                            value={fullName}
                            className='inputTagItem'
                            onChange={(e) => { setFullName(e.target.value); }}
                            disabled={editTogle}
                        />
                        {fullNameError && <p className="error-text">{fullNameError}</p>}
                    </div>

                    <div className="inputItem">
                        <label className='inputItemText'>Email address</label>
                        <input type='text' disabled={true} value={email} onChange={(e) => setEmail(e.target.value)} className='inputTagItem' />
                    </div>

                    {/* <div className="inputItem">
                        <label className='inputItemText'>Card details</label>
                        <input type='text' className='inputTagItem' />
                    </div> */}

                    {!lodder && <div className="subbmit-button">
                        <button
                            disabled={editTogle}
                            type="button" onClick={() => { EditFunction(); }} >
                            Save
                        </button>
                    </div>}

                    {lodder &&
                        <div className='subbmit-button'>
                            <Box sx={{ alignItems: "center" }}>
                                <CircularProgress sx={{ color: " #1D3250", size: "10px" }} />
                            </Box>
                        </div>
                    }
                </div>

            </div>

        </div>
    )
}
