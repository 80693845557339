import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import BoltOutlinedIcon from "@mui/icons-material/BoltOutlined";
import apiCallObject from "../../helper/api/chat-gpt";
import subScriptionAPIObject from "../../helper/api/subscribtion.api";
import webApp from "../../helper/prompt/prompt.helper";
import "./preparation.css";
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import Swal from "sweetalert2";

const useAutosizeTextArea = (
  textAreaRef,
  value
) => {
  useEffect(() => {
    if (textAreaRef) {
      const scrollHeight = textAreaRef.scrollHeight;
      textAreaRef.style.height = scrollHeight > 500 ? "450px" : !value ? `${52}px` : scrollHeight + "px";
      textAreaRef.scrollTop = scrollHeight;
    }
  }, [textAreaRef, value]);
};

const Preparation = () => {
  const isListeningRef = useRef(true);
  const navigate = useNavigate();
  const [textarea, setTextarea] = useState("");
  const [lodder, setLodder] = useState(false);
  const [summary, setSummary] = useState("");
  const [skills, setSkills] = useState("");
  const [career, setCareer] = useState("");
  const [readness, setReadness] = useState("");
  const [questions, setQuestions] = useState("");
  const [btnText, setBtnText] = useState("Generate");
  const [textAreaError, setTextareaError] = useState("");
  const [summaryCopy, setSummaryCopy] = useState(false);
  const [skillsCopy, setSkillsCopy] = useState(false);
  const [careerCopy, setCareerCopy] = useState(false);
  const [readnessCopy, setReadnessCopy] = useState(false);
  const [questionsCopy, setQuestionsCopy] = useState(false);
  const [currentPlanId, setCurrentPlanId] = useState("");

  const summaryTextRef = useRef(null);
  const skillsTextRef = useRef(null);
  const careerTextRef = useRef(null);
  const readnessTextRef = useRef(null);
  const questionsTextRef = useRef(null);
  useAutosizeTextArea(summaryTextRef.current, summary);
  useAutosizeTextArea(skillsTextRef.current, skills);
  useAutosizeTextArea(careerTextRef.current, career);
  useAutosizeTextArea(readnessTextRef.current, readness);
  useAutosizeTextArea(questionsTextRef.current, questions);

  useEffect(() => {
    setBtnText("Genrate")
    setSummary("");
    setCareer("");
    setSkills("");
    setReadness("");
    setQuestions("");
  }, [textarea])

  useEffect(() => {
    isSubscribedUser();
  }, [])


  const isSubscribedUser = () => {
    const authToken = localStorage.getItem("auth-token");
    subScriptionAPIObject.getValidPlan(authToken, async (result) => {
      if (!result?.data.status) {
        if (result?.data?.msg === "Invalid link" || result?.data?.msg === 'Link expired') {
          Swal.fire({
            icon: "info",
            title: "",
            text: "Logged in session has expired. Please log in again",
            timer: 2500,
            showConfirmButton: false,
          });
          localStorage.clear();
          window.location.reload(true)
          return navigate('/auth/login')
        }

        Swal.fire({
          icon: 'info',
          title: "",
          text: "You haven't subscribed to a tier yet.",
          showConfirmButton: true,
        })
        return navigate('/home/pricing')
      }
      setCurrentPlanId(result?.data?.data.planId);
    })
  }

  const {
    INTERVIEW_SUMMARY,
    SKILLS_ABILITIES_AND_TOOLS,
    ESSENTIAL_SKILLS_AND_ABILITIES,
    INTERVIEW_READINESS,
    INTERVIEW_QUESTIONS,
  } = webApp.prompt,


    getResponseData = (text, prompt, setResponse) => {
      return new Promise(async (reslove, reject) => {
        const sse = await apiCallObject.chatgpt(text, prompt);

        sse.addEventListener("open", () => {
          // console.log("SSE opened!");
          if (!isListeningRef.current) { sse.close(); reslove(true) }
        });

        sse.addEventListener("message", async ({ data }) => {
          if (!isListeningRef.current) {
            sse.close();
            reslove(true)
          }
          if (data !== "[DONE]") {
            let msgObj = JSON.parse(data);
            if (msgObj.text !== undefined)
              setResponse((r) => r + msgObj.text);
          }
        });

        sse.addEventListener("close", (e) => {
          sse.close();
          return reslove(true);
        });

        sse.addEventListener("error", (e) => {
          console.log("An error occurred while attempting to connect.", e);
          sse.close();
          return reject(false);
        });
      });
    },

    submitFun = async () => {
      setBtnText("Genrate")
      setSummary("");
      setCareer("");
      setSkills("");
      setReadness("");
      setQuestions("");

      if (textarea === "") {
        return setTextareaError("Job description must be required.");
      }

      try {
        let authToken = localStorage.getItem("auth-token"),
          payload = { hitCount: 'preparation', planId: currentPlanId, dateOfDay: new Date() }
        await subScriptionAPIObject.eventCount(authToken, payload)


      } catch (error) {
        if (error?.response?.data?.msg === "Invalid link" || error?.response?.data?.msg === 'Link expired') {
          Swal.fire({
            icon: "info",
            title: "",
            text: "Logged in session has expired. Please log in again",
            timer: 2500,
            showConfirmButton: false,
          });
          localStorage.clear();
          window.location.reload(true)
          return navigate('/auth/login')
        }
        if (error?.response?.status === 409) {
          Swal.fire({
            icon: "info",
            title: "",
            text: error?.response?.data?.msg,
            showConfirmButton: true,
          });
          return navigate('/home/pricing')
        }
        return Swal.fire({
          icon: "error",
          title: "",
          timer: 2500,
          text: error?.response?.data?.msg,
          showConfirmButton: false,
        });
      }


      setLodder(true);

      let result1 = new Promise(async (reslove, reject) => {
        try {
          await getResponseData(textarea, INTERVIEW_SUMMARY, setSummary);
          await getResponseData(textarea, SKILLS_ABILITIES_AND_TOOLS, setSkills);
          await getResponseData(textarea, ESSENTIAL_SKILLS_AND_ABILITIES, setCareer);
          await getResponseData(textarea, INTERVIEW_READINESS, setReadness);
          await getResponseData(textarea, INTERVIEW_QUESTIONS, setQuestions);


          return reslove(true);
        } catch (err) {
          return reject(false);
        }
      });
      Promise.allSettled([result1]).then((values) => {
        setLodder(false);
        setBtnText("Regenrate")
        isListeningRef.current = true
      });
    },

    onCopied = async (value, setValue) => {
      if (value) {
        navigator.clipboard.writeText(value)
        setValue(true);
        setTimeout(() => {
          setValue(false);
        }, 1100)
      }
    }

  return (
    <div>
      <div className="pre-header">
        <h1>Job Interview Preparation Assistant</h1>
      </div>
      <div className="preparation">
        <div className="pre-col">
          <div className="input-col">
            <textarea
              className="pre-input"
              placeholder="Write a job description here..."
              defaultValue={textarea}
              onChange={(e) => setTextarea(e.target.value)}
              style={{ pointerEvents: !isListeningRef.current ? 'none' : 'visible' }}
              rows={7}
              cols={6}
            />
            {textAreaError && <h2 className="input-error"  >{textAreaError}</h2>}

          </div>

          {lodder
            ? <button className="pre-input-btn" onClick={() => { isListeningRef.current = false; }}>
              Stop generating
            </button> :
            <button className="pre-input-btn" onClick={() => submitFun()}>
              <div className="tsk-input-inner-text">
                <span>{btnText} </span>
                <BoltOutlinedIcon className="btn-icon" style={{ fontSize: '15px' }} />
              </div>
            </button>
          }
        </div>

        <div className="pre-col">

          <div className="pre-output">
            <div className="pre-labal-div">
              <div className="pre-title">
                <label >Job Summary</label>
                <div className="col-line" ></div>
              </div>
              {
                summaryCopy
                  ?
                  <span className="copied-style" >Copied</span>
                  :
                  <ContentCopyOutlinedIcon onClick={() => onCopied(summary, setSummaryCopy)} className="copy-icon" />
              }
            </div>
            <textarea
              className="pre-output-textarea"
              value={summary}
              rows={1}
              ref={summaryTextRef}
              cols={6}
            />
          </div>

          <div className="pre-output">
            <div className="pre-labal-div">
              <div className="pre-title">
                <label >skills</label>
                <div className="col-line" ></div>
              </div>
              {skillsCopy ? <span className="copied-style" >Copied</span> : <ContentCopyOutlinedIcon onClick={() => onCopied(skills, setSkillsCopy)} className="copy-icon" />}
            </div>
            <textarea
              className="pre-output-textarea"
              value={skills}
              rows={1}
              ref={skillsTextRef}
              cols={6}
            />
          </div>

          <div className="pre-output">
            <div className="pre-labal-div">
              <div className="pre-title">
                <label >Career</label>
                <div className="col-line" ></div>
              </div>
              {careerCopy ? <span className="copied-style" >Copied</span> : <ContentCopyOutlinedIcon onClick={() => onCopied(career, setCareerCopy)} className="copy-icon" />}
            </div>
            <textarea
              className="pre-output-textarea"
              value={career}
              rows={1}
              ref={careerTextRef}
              cols={6}
            />
          </div>


          <div className="pre-output">
            <div className="pre-labal-div">
              <div className="pre-title">
                <label >Job Readness</label>
                <div className="col-line" ></div>
              </div>
              {readnessCopy ? <span className="copied-style" >Copied</span> : <ContentCopyOutlinedIcon onClick={() => onCopied(readness, setReadnessCopy)} className="copy-icon" />}
            </div>
            <textarea
              className="pre-output-textarea"
              value={readness}
              rows={1}
              cols={6}
              ref={readnessTextRef}
            />
          </div>

          <div className="pre-output">
            <div className="pre-labal-div">
              <div className="pre-title">
                <label >Interview questions</label>
                <div className="col-line" ></div>
              </div>
              {questionsCopy ? <span className="copied-style" >Copied</span> : <ContentCopyOutlinedIcon onClick={() => onCopied(questions, setQuestionsCopy)} className="copy-icon" />}
            </div>
            <textarea
              className="pre-output-textarea"
              value={questions}
              rows={1}
              cols={6}
              ref={questionsTextRef}
            />
          </div>

        </div>
      </div>
    </div>
  );
};

export default Preparation;