import config from "../../../config/config";

let apiCallObject = {
    chatgpt: async (text, prompt) => {
        const url = new URL(`${config.API_URL}/chatgpt/genratedata`);
        url.searchParams.append('payload', JSON.stringify({ text, prompt }));
        return new EventSource(url);
    },
    taskGenration: async (prompt) => {
        const url = new URL(`${config.API_URL}/chatgpt/taskgenration`);
        url.searchParams.append('payload', JSON.stringify({ prompt }));
        return new EventSource(url);
    }
}

export default apiCallObject;