import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import authAPIObject from "../../../helper/api/auth.api";
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import "./verification.css";

const Verification = () => {
  const [searchParams] = useSearchParams(),
    [isVerified, setIsVerified] = useState(null),
    [errorMsg, setErrorMsg] = useState("");

  const emailVerifyFunction = () => {
    authAPIObject.emailVerify(searchParams.get("token"), (result) => {
      setTimeout(() => {
        if (result.data.status === "false" || result.data.status === false) {
          setErrorMsg(result.data.msg);
          return setIsVerified(false);
        }
        setIsVerified(true);
      }, [3000]);
    });
  };

  useEffect(() => {
    if (isVerified == null) emailVerifyFunction();
    // eslint-disable-next-line 
  }, []);


  return isVerified === null ? (
    <div className="verify-card">
      <Box sx={{ display: "flex", margin: "20px 00px" }}>
        <CircularProgress sx={{ color: " #1D3250" }} />
      </Box>
      <h2 className="verify-text">We will be there in a few seconds...</h2>
    </div>
  ) : (
    <div className="verify-card">
      {isVerified ? (
        <div className="main-card">
          <CheckCircleOutlinedIcon sx={{ color: ' #1D3250' }} fontSize="large" />
          <h2 className="verify-text">
            Close this and return to your previous window{" "}
          </h2>

        </div>
      ) : (
        <div className="main-card">
          <h1 style={{ fontSize: '25px'}}  className="error-msg" >{errorMsg}</h1>
          <ErrorOutlineIcon sx={{ color: 'red' }} fontSize="large" />
          <h2 className="verify-text">
            Return to original window to request for a new link
          </h2>
          <h2 className="verify-text">You can close this window</h2>
        </div>
      )}
      <div className="bottom-text">
        <p>Self-custodial verify by</p>
        <p>JobGuru.ai</p>
      </div>
    </div>
  );
};

export default Verification;


