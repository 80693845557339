import React from 'react';
import Navbar from '../../navbar/navbar';
import Footer from "../../footer/footer";
import './about.css'

const About = () => {
    return (
        <>
            <section className="aboutus">
                <h1>About Us</h1>
                <div className="aboutus-content">
                    <div className="aboutus-title">
                        <p>Empowering Your Career Path: From Job Interview Preparation to Task Breakdown and Document Insights</p>
                        <p>
                            At Job Guru, we provide comprehensive job interview preparation based on individual job descriptions, intuitive task breakdown solutions tailored to your timeframe, and an innovative chat-to-document feature for document queries. Elevate your professional journey with us today.
                        </p>
                        <p>
                            Empowering you with specialized modules for job interview preparation and tailored task breakdowns, along with the capability to query uploaded documents, we're here to streamline your journey towards achieving your career goals
                        </p>
                    </div>
                    <div className="aboutus-images">
                        <img src="./assets/about.jpg" alt="img1" />
                    </div>
                </div>
            </section>
        </>
    )

}

export default About;