import axios from "axios";
import config from "../../../config/config";

let userAPIObject = {
  getUser: async (token, callback) => {
    axios({
      method: "GET",
      url: `${config.API_URL}/user/get`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        return callback(response);
      })
      .catch((err) => {
        return callback(err.response);
      });
  },
  updateUser: async (token,data, callback) => {
    axios({
      method: "POST",
      url: `${config.API_URL}/user/updateuserdetails`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    })
      .then((response) => {
        return callback(response);
      })
      .catch((err) => {
        return callback(err.response);
      });
  },
};

export default userAPIObject;
