import React, { useEffect, useState } from "react";
// import CircularProgress from "@mui/material/CircularProgress";
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import { useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import "./sentEmail.css";
import authAPIObject from "../../../helper/api/auth.api";

const SentForgetEmail = () => {
    const location = useLocation();
    const [email, setEmail] = useState("");
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(60);
    const [apiResponse, setApiResponse] = useState(true);
    const [msg, setMsg] = useState("");

    useEffect(() => {
        let email = location.state?.email;
        setEmail(email);
    }, []);


    const ResendEmail = () => {

        authAPIObject.foregetPassword(email, (result) => {
            if (result?.data?.status === "false" || result?.data?.status === false) {
                setApiResponse(false)
                setMsg("Something went wrong. Please send again");
                return setTimeout(() => {
                    setMsg("");
                }, 2000);
            }
            setMsg("Email sent successfully.");
            setTimeout(() => {
                setMsg("");
            }, 2000);
            return setSeconds(60);
        });
    };

    useEffect(() => {
        var myInterval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }
            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(myInterval);
                } else {
                    setMinutes(minutes - 1);
                    setSeconds(59);
                }
            }
        }, 1000);
        return () => {
            clearInterval(myInterval);
        };
    }, [seconds]);

    return (
        <div className="verify-card">
            <div className="main-card">
                <h1>Email sent successfully</h1>
                <Box sx={{ alignItems: "center", margin: "15px" }}>
                    <CheckCircleOutlinedIcon sx={{ color: " #1D3250", size: "10px" }} fontSize="large" />
                </Box>
                <h2 className="verify-text">
                    An email has been sent to{" "}
                    <label style={{ color: " #1D3250" }}>{email}.</label>
                </h2>
                <h2 className="verify-text">please verify your email to continue.</h2>
            </div>

            <div className="resend_email">
                <p>Did not get the email?</p>
                {minutes === 0 && seconds === 0 ? (
                    <label onClick={() => ResendEmail()}>Resend email</label>
                ) : (
                    <>
                        <h3 style={{ color: " #1D3250" }}>
                            Resend mail in {minutes}:{seconds < 10 ? `0${seconds}` : seconds}{" "}
                            sec
                        </h3>
                        {msg && <h1 style={{ color: apiResponse ? '#484848' : '  #1D3250', marginTop: '10px' }}>{msg}</h1>}
                    </>
                )}
            </div>

            <div className="bottom-text">
                <p>Self-custodial verify by</p>
                <p>JobGuru.ai</p>
            </div>
        </div>
    );
};

export default SentForgetEmail;
