import React, { useEffect, useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import authAPIObject from "../../../helper/api/auth.api";
import "./signup.css";
import Swal from "sweetalert2";

const SignUp = () => {
  const navigate = useNavigate();
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [fullNameError, setFullNameError] = useState("");
  const [emailError, setemailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [passwordTogle, setPasswordTogle] = useState(false);
  const [confirmPasswordTogle, setConfirmPasswordTogle] = useState(false);
  const [message, setMessage] = useState(""),
    [lodder, setLodder] = useState(false);

  const emailRegex =
    /^[A-Z0-9_'%=+!`#~$*?^{}&|-]+([\.][A-Z0-9_'%=+!`#~$*?^{}&|-]+)*@[A-Z0-9-]+(\.[A-Z0-9-]+)+$/i;

  const register = () => {
    if (fullName === "" || fullName === "undefined") {
      setFullNameError("full Name must be required!");
    } else {
      setFullNameError("");
    }

    if (email === "" || email === "undefined") {
      setemailError("Email must be required!");
    } else if (emailRegex.test(email) === false) {
      setemailError("Invalid email!");
    } else {
      setemailError("");
    }
    if (password === "" || password === undefined) {
      setPasswordError("Password must be required!");
    } else if (password.length < 8) {
      setPasswordError("Password too small. must be atleast 8 characters");
    } else {
      setPasswordError("");
    }
    if (confirmPassword === "" || confirmPassword === undefined) {
      setConfirmPasswordError("Confirm password must be required!");
    } else if (confirmPassword !== password) {
      setConfirmPasswordError("Password and confirm password must be same!");
    } else {
      setConfirmPasswordError("");
    }

    if (
      fullName !== "" &&
      email !== "" &&
      password !== "" &&
      confirmPassword !== ""
    ) {
      setLodder(true);
      authAPIObject.register(
        {
          fullName,
          email,
          password,
          loginDevice: "WEB",
          loginType: "EMAIL",
          deviceToken: "abcdefghijklmnopqrstuvwxyz",
        },
        (result) => {
          setLodder(false);
          if (result.data.status === "false" || result.data.status === false) {
            return Swal.fire({
              icon: "error",
              title: `${result?.data.msg}`,
              timer: 2500,
              showConfirmButton: false,
            });
          }
          // console.log("result?.data?.data.data._id", result?.data?.data);
          // localStorage.setItem("auth-token", result?.data?);
          navigate("/user/email/sent/", {
            state: {
              userId: result?.data?.data.data._id,
              email: email,
            },
          });
        }
      );
    }
  };

  useEffect(() => {
    setFullNameError("");
    setMessage("");
  }, [fullName]);
  useEffect(() => {
    setMessage("");
    setemailError("");
  }, [email]);
  useEffect(() => {
    setMessage("");
    setPasswordError("");
  }, [password]);
  useEffect(() => {
    setMessage("");
    setConfirmPasswordError("");
  }, [confirmPassword]);

  return (
    <>
      <div className="display-card">
        <div className="auth-cart">
          <div className="auth-header">
            <img
              src="./assets/app-logo.png"
              alt="loading..."
              height="100px"
              width="300px"
            />
            <h1>Enter your details to Signup</h1>
          </div>
          <h6 className="error-msg">{message}</h6>
          <div className="input-cart">
            <div className="input-div">
              <input
                className="input-tag"
                type="text"
                placeholder="Full Name"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
              />
              <p className="error-text">{fullNameError}</p>
            </div>
            <div className="input-div">
              <input
                className="input-tag"
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <p className="error-text">{emailError}</p>
            </div>
            <div className="input-div">
              <div className="Password-div">
                <input
                  className="password-tag"
                  type={passwordTogle ? "text" : "password"}
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                {passwordTogle ? (
                  <VisibilityOffIcon
                    className="password-eye"
                    onClick={() => setPasswordTogle(false)}
                  />
                ) : (
                  <VisibilityIcon
                    className="password-eye"
                    onClick={() => setPasswordTogle(true)}
                  />
                )}
              </div>
              <p className="error-text">{passwordError}</p>
            </div>
            <div className="input-div">
              <div className="Password-div">
                <input
                  className="password-tag"
                  type={confirmPasswordTogle ? "text" : "password"}
                  placeholder="Confirm password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
                {confirmPasswordTogle ? (
                  <VisibilityOffIcon
                    className="password-eye"
                    onClick={() => setConfirmPasswordTogle(false)}
                  />
                ) : (
                  <VisibilityIcon
                    className="password-eye"
                    onClick={() => setConfirmPasswordTogle(true)}
                  />
                )}
              </div>
              <p className="error-text">{confirmPasswordError}</p>
            </div>
          </div>
          <div className="sub-button">
            {lodder ? (
              <Box sx={{ alignItems: "center" }}>
                <CircularProgress sx={{ color: " #1D3250", size: "10px" }} />
              </Box>
            ) : (
              <button
                type="button"
                onClick={() => {
                  register();
                }}
              >
                Sign-Up
              </button>
            )}
          </div>
          <div className="bottam-text">
            <p>
              Already have an account? <label onClick={() => navigate('/auth/login/')} >Sign-in</label>{" "}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignUp;
