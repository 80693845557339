import React, { useEffect, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import authAPIObject from "../../../helper/api/auth.api";
import Swal from "sweetalert2";



const ForgetPassword = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [emailError, setemailError] = useState(""),
        [lodder, setLodder] = useState(false);

    const emailRegex =
        /^[A-Z0-9_'%=+!`#~$*?^{}&|-]+([\.][A-Z0-9_'%=+!`#~$*?^{}&|-]+)*@[A-Z0-9-]+(\.[A-Z0-9-]+)+$/i;

    const submit = () => {
        if (email === "" || email === "undefined") {
            setemailError("Email must be required!");
        } else if (emailRegex.test(email) === false) {
            setemailError("Invalid email!");
        } else {
            setemailError("");
        }
        if (email !== "") {
            setLodder(true);
            authAPIObject.foregetPassword(email, (result) => {
                setLodder(false);
                if (result?.data?.status === "false" || result?.data?.status === false) {
                    return Swal.fire({
                        icon: "error",
                        title: `${result?.data.msg}`,
                        timer: 2500,
                        showConfirmButton: false,
                    });
                }
                navigate("/auth/forget/sent/", {
                    state: {
                        email,
                    },
                });
            }
            );
        }
    };

    useEffect(() => {
        setemailError("");
    }, [email]);



    return (
        <div className="login-card">
            <div className="auth-cart">
                <div className="auth-header">
                    <img
                        src="./assets/app-logo.png"
                        alt="loading..."
                        height="100px"
                        width="300px"
                    />
                    <h1>Forget Your Password ?</h1>
                </div>
                <div className="input-cart">
                    <div className="input-div">
                        <input
                            className="input-tag"
                            type="email"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <p className="error-text">{emailError}</p>
                    </div>

                </div>

                <div className="subbmit-button" style={{ marginTop: '20px' }}>
                    {lodder ? (
                        <Box sx={{ alignItems: "center" }}>
                            <CircularProgress sx={{ color: " #1D3250", size: "10px" }} />
                        </Box>
                    ) : (
                        <button
                            type="button"
                            onClick={() => {
                                submit();
                            }}
                        >
                            Submit
                        </button>
                    )}
                </div>
                <div className="bottam-text" >
                    <p>
                        Back to <label onClick={() => { navigate('/auth/login/') }}>Sign In?</label>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default ForgetPassword;
