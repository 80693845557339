import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import BoltOutlinedIcon from "@mui/icons-material/BoltOutlined";
import apiCallObject from "../../helper/api/chat-gpt";
import subScriptionAPIObject from "../../helper/api/subscribtion.api";
import webApp from "../../helper/prompt/prompt.helper";
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import "./taskGenration.css";
import Swal from "sweetalert2";

const useAutosizeTextArea = (
  textAreaRef,
  value
) => {
  useEffect(() => {
    if (textAreaRef) {
      const scrollHeight = textAreaRef.scrollHeight;
      textAreaRef.style.height = scrollHeight > 500 ? "450px" : !value ? `${52}px` : scrollHeight + "px";
      textAreaRef.scrollTop = scrollHeight;
    }

  }, [textAreaRef, value]);
};


const useAutosizeTextArea2 = (
  textAreaRef,
  value
) => {
  useEffect(() => {
    if (textAreaRef) {
      const scrollHeight = textAreaRef.scrollHeight;
      textAreaRef.style.height = scrollHeight > 500 ? "450px" : !value ? `${20}px` : scrollHeight + "px";
      textAreaRef.scrollTop = scrollHeight;
    }

  }, [textAreaRef, value]);
};

const TaskGenration = () => {

  const isListeningRef = useRef(true);
  const navigate = useNavigate();
  const [jobProfile, setJobProfile] = useState(""),
    [duration, setDuration] = useState("1 Week"),
    [taskScope, setTaskScope] = useState(""),
    [description, setDescription] = useState(""),
    [lodder, setLodder] = useState(false),
    [btnText, setBtnText] = useState("Generate"),
    [jobProfileError, setJobProfileError] = useState(""),
    [taskScopeError, setTaskScopeError] = useState(""),
    [weekValue, setWeekValue] = useState(1)


  const [week1Text, setWeek1Text] = useState(""),
    [week2Text, setWeek2Text] = useState(""),
    [week3Text, setWeek3Text] = useState(""),
    [week4Text, setWeek4Text] = useState("");

  const week1TextRef = useRef(null),
    week2TextRef = useRef(null),
    week3TextRef = useRef(null),
    week4TextRef = useRef(null),
    taskScopeTextRef = useRef(null),
    descriptionTextRef = useRef(null);

  useAutosizeTextArea(week1TextRef.current, week1Text);
  useAutosizeTextArea(week2TextRef.current, week2Text);
  useAutosizeTextArea(week3TextRef.current, week3Text);
  useAutosizeTextArea(week4TextRef.current, week4Text);
  useAutosizeTextArea2(taskScopeTextRef.current, taskScope);
  useAutosizeTextArea2(descriptionTextRef.current, description);
  const [currentPlanId, setCurrentPlanId] = useState("");
  const [week1TextCopy, setWeek1TextCopy] = useState(false),
    [week2TextCopy, setWeek2TextCopy] = useState(false),
    [week3TextCopy, setWeek3TextCopy] = useState(false),
    [week4TextCopy, setWeek4TextCopy] = useState(false);


  useEffect(() => {
    isSubscribedUser();
  }, [])


  const isSubscribedUser = () => {
    const authToken = localStorage.getItem("auth-token");
    subScriptionAPIObject.getValidPlan(authToken, async (result) => {
      if (!result?.data.status) {

        if (result?.data?.msg === "Invalid link" || result?.data?.msg === 'Link expired') {
          Swal.fire({
            icon: "info",
            title: "",
            text: "Logged in session has expired. Please log in again",
            timer: 2500,
            showConfirmButton: false,
          });
          localStorage.clear();
          window.location.reload(true)
          return navigate('/auth/login')
        }

        Swal.fire({
          icon: 'info',
          title: "",
          text: "You haven't subscribed to a tier yet.",
          showConfirmButton: true,
        })
        return navigate('/home/pricing')
      }
      setCurrentPlanId(result?.data?.data.planId);
    })

  }


  const {
    TASK_GENRATION,
    TASK_GENRATION_1WEEK,
    TASK_GENRATION_2WEEK,
    TASK_GENRATION_3WEEK,
    TASK_GENRATION_4WEEK
  } = webApp.prompt;

  useEffect(() => {
    setJobProfileError("")
  }, [jobProfile])
  useEffect(() => {
    setTaskScopeError("")
  }, [taskScope])


  useEffect(() => {
    setBtnText("Genrate")
    setWeek1Text("")
    setWeek2Text("")
    setWeek3Text("")
    setWeek4Text("")

  }, [jobProfile, taskScope, duration, description])


  let array = [{ role: "system", content: "You are a helpful assistant." }];

  const taskFunction = async (setResponse) => {
    return new Promise(async (reslove, reject) => {
      const sse = await apiCallObject.taskGenration(array);
      sse.addEventListener("open", () => {
        // console.log("SSE opened!");
        if (!isListeningRef.current) { sse.close(); reslove(true) }
      });
      sse.addEventListener("message", async ({ data }) => {
        if (data !== "[DONE]") {
          if (!isListeningRef.current) { sse.close(); reslove(true) }
          let msgObj = await JSON.parse(data);
          if (msgObj.text !== undefined) {
            setResponse((r) => r + msgObj.text);
          }
        } else {
          sse.close();
        }
      });
      sse.addEventListener("close", (e) => {
        sse.close();
        return reslove(true);
      });
      sse.addEventListener("error", (e) => {
        console.log("An error occurred while attempting to connect.", e);
        sse.close();
        return reject(false);
      });
    });
  };

  const submitFun = async () => {
    setWeek1Text("")
    setWeek2Text("")
    setWeek3Text("")
    setWeek4Text("")

    if (jobProfile === "") {
      return setJobProfileError("Task user profile must be required.");
    }
    if (taskScope === "") {
      return setTaskScopeError("Task scope must be required.");
    }

    try {
      let authToken = localStorage.getItem("auth-token"),
        payload = { hitCount: 'task', planId: currentPlanId, dateOfDay: new Date() }
      await subScriptionAPIObject.eventCount(authToken, payload)


    } catch (error) {

      if (error?.response?.data?.msg === "Invalid link" || error?.response?.data?.msg === 'Link expired') {
        Swal.fire({
          icon: "info",
          title: "",
          text: "Logged in session has expired. Please log in again",
          timer: 2500,
          showConfirmButton: false,
        });
        localStorage.clear();
        window.location.reload(true)
        return navigate('/auth/login')
      }
      if (error?.response?.status === 409) {
        Swal.fire({
          icon: "info",
          title: "",
          text: error?.response?.data?.msg,
          showConfirmButton: true,
        });
        return navigate('/home/pricing')
      }
      return Swal.fire({
        icon: "error",
        title: "",
        timer: 2500,
        text: error?.response?.data?.msg,
        showConfirmButton: false,
      });
    }



    setLodder(true);

    let text = `Job profile: ${jobProfile}, Task scope: ${taskScope}, Task duration: ${duration}, ${description ? `Task description: ${description}` : ""}`;

    if (duration === "1 Week") {
      let result = new Promise(async (resolve, reject) => {
        const sse = await apiCallObject.chatgpt(text, TASK_GENRATION_1WEEK);
        sse.addEventListener("open", () => {
          // console.log("SSE opened!");
          if (!isListeningRef.current) { sse.close(); resolve(true) }
        });

        //listen to incoming messages
        sse.addEventListener("message", async ({ data }) => {
          if (!isListeningRef.current) { sse.close(); resolve(true) }
          if (data !== "[DONE]") {
            let msgObj = JSON.parse(data);
            if (msgObj.text !== undefined) setWeek1Text((r) => r + msgObj.text);
          }
        });

        sse.addEventListener("close", (e) => {
          sse.close();
          return resolve(true);
        });

        sse.addEventListener("error", (e) => {
          console.log("An error occurred while attempting to connect.", e);
          sse.close();
          return reject(false);
        });
      });

      Promise.allSettled([result]).then((values) => {
        setLodder(false);
        setBtnText("Regenrate")
        isListeningRef.current = true
      });
      return null;
    }

    if (duration === "2 Weeks" || duration === "3 Weeks" || duration === "4 Weeks" || duration === "5 Weeks") {

      let result = new Promise(async (resolve, reject) => {
        try {
          array.push({ role: "user", content: `${text} /n/ ${TASK_GENRATION}` });
          await taskFunction(setWeek1Text);


          array.push({ role: "system", content: '1st week task Completed.' },
            { role: "user", content: TASK_GENRATION_2WEEK });
          await taskFunction(setWeek2Text);


          if (duration === "2 Weeks") {
            return resolve(true);
          }

          array.push({ role: "system", content: '2nd week task Completed.' },
            { role: "user", content: TASK_GENRATION_3WEEK });

          await taskFunction(setWeek3Text);


          if (duration === "3 Weeks") {
            return resolve(true);
          }

          array.push({ role: "system", content: '3rd week task Completed.' }, { role: "user", content: TASK_GENRATION_4WEEK });
          await taskFunction(setWeek4Text);

          return resolve(true);

        } catch (er) {
          reject(er);
        }
      });
      Promise.allSettled([result]).then((values) => {
        setLodder(false);
        setBtnText("Regenrate")
        isListeningRef.current = true
      });
    }
  };

  const submitDuration = (val) => {
    setDuration(val);
    if (val === "1 Week") {
      setWeekValue(1)
    }
    if (val === "2 Weeks") {
      setWeekValue(2)
    }
    if (val === "3 Weeks") {
      setWeekValue(3)
    }
    if (val === "4 Weeks") {
      setWeekValue(4)
    }
  }

  const onCopied = async (value, setValue) => {
    if (value) {
      navigator.clipboard.writeText(value)
      setValue(true);
      setTimeout(() => {
        setValue(false);
      }, 1100)
    }
  }

  return (
    <div>
      <div className="tsk-header">
        <h1>Task Generation Assistant</h1>
      </div>
      <div className="preparation">
        <div className="pre-col">
          <div className="input-col">
            <div className="tsk-input-div">
              <label className="tsk-input-title" >Job title</label>
              <div className="input-child" >
                <div>|</div>
                <input
                  className="tsk-input-tag"
                  type="text"
                  value={jobProfile}
                  style={{ pointerEvents: !isListeningRef.current ? 'none' : 'visible' }}
                  onChange={(e) => setJobProfile(e.target.value)}
                />
              </div>
            </div>
            {jobProfileError && <h2 className="input-error" >{jobProfileError}</h2>}
          </div>

          <div className="input-col">
            <div className="tsk-input-div">
              <label className="tsk-input-title" >Duration of task</label>
              <div className="input-child">
                <div>|</div>
                <select
                  value={duration}
                  onChange={(e) => submitDuration(e.target.value)}
                  id="1 week"
                  className="tsk-input-tag"
                  style={{ pointerEvents: !isListeningRef.current ? 'none' : 'visible' }}
                >
                  <option value="1 Week">1 Week</option>
                  <option value="2 Weeks">2 Weeks</option>
                  <option value="3 Weeks">3 Weeks</option>
                  <option value="4 Weeks">4 Weeks</option>
                </select>
              </div>
            </div>
          </div>

          <div className="input-col">
            <div className="tsk-input-div">
              <label className="tsk-input-title"   >Task scope</label>
              <div className="input-child">
                <div>|</div>
                <textarea
                  className="tsk-input-tag"
                  defaultValue={taskScope}
                  ref={taskScopeTextRef}
                  style={{ pointerEvents: !isListeningRef.current ? 'none' : 'visible' }}
                  onChange={(e) => setTaskScope(e.target.value)}
                  rows={1}
                  cols={6}
                />
              </div>
            </div>
            {taskScopeError && <h2 className="input-error"  >{taskScopeError}</h2>}
          </div>


          <div className="input-col">
            <div className="tsk-input-div">
              <label className="tsk-input-title"  >Task description</label>
              <div className="input-child">
                <div>|</div>
                <textarea
                  className="tsk-input-tag"
                  defaultValue={description}
                  ref={descriptionTextRef}
                  style={{ pointerEvents: !isListeningRef.current ? 'none' : 'visible' }}
                  onChange={(e) => setDescription(e.target.value)}
                  rows={1}
                  cols={6}
                />
              </div>
            </div>
          </div>
          {lodder
            ? <button className="tsk-input-btn" onClick={() => { isListeningRef.current = false; }}>
              Stop generating
            </button> :
            <button className="tsk-input-btn" onClick={() => submitFun()}>
              <div className="tsk-input-inner-text">
                <span>{btnText} </span>
                <BoltOutlinedIcon className="btn-icon" style={{ fontSize: '15px' }} />
              </div>
            </button>
          }
        </div>
        <div className="pre-col">

          <div className="pre-output">
            <div className="pre-labal-div">
              <div className="pre-title">
                <label >Week 1</label>
                <div className="col-line" ></div>
              </div>
              {week1TextCopy ? <span className="copied-style" >Copied</span> : <ContentCopyOutlinedIcon onClick={() => onCopied(week1Text, setWeek1TextCopy)} className="copy-icon" />}
            </div>
            <textarea
              className="pre-output-textarea"
              value={week1Text}
              rows={1}
              ref={week1TextRef}
              cols={6}
            />
          </div>

          {weekValue > 1 && <div className="pre-output">
            <div className="pre-labal-div">
              <div className="pre-title">
                <label >Week 2</label>
                <div className="col-line" ></div>
              </div>
              {week2TextCopy ? <span className="copied-style" >Copied</span> : <ContentCopyOutlinedIcon onClick={() => onCopied(week2Text, setWeek2TextCopy)} className="copy-icon" />}
            </div>
            <textarea
              className="pre-output-textarea"
              value={week2Text}
              rows={1}
              ref={week2TextRef}
              cols={6}
            />
          </div>
          }

          {weekValue > 2 && <div className="pre-output">
            <div className="pre-labal-div">
              <div className="pre-title">
                <label >Week 3</label>
                <div className="col-line" ></div>
              </div>
              {week3TextCopy ? <span className="copied-style" >Copied</span> : <ContentCopyOutlinedIcon onClick={() => onCopied(week3Text, setWeek3TextCopy)} className="copy-icon" />}
            </div>
            <textarea
              className="pre-output-textarea"
              value={week3Text}
              rows={1}
              ref={week3TextRef}
              cols={6}
            />
          </div>
          }

          {weekValue > 3 && <div className="pre-output">
            <div className="pre-labal-div">
              <div className="pre-title">
                <label >Week 4</label>
                <div className="col-line" ></div>
              </div>
              {week4TextCopy ? <span className="copied-style" >Copied</span> : <ContentCopyOutlinedIcon onClick={() => onCopied(week4Text, setWeek4TextCopy)} className="copy-icon" />}
            </div>
            <textarea
              className="pre-output-textarea"
              value={week4Text}
              rows={1}
              cols={6}
              ref={week4TextRef}
            />
          </div>
          }

        </div>
      </div>
    </div>
  );
};

export default TaskGenration;