import React, { useEffect, useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import { Link, useNavigate } from "react-router-dom";
import authAPIObject from "../../../helper/api/auth.api";
import "./login.css";
import Swal from "sweetalert2";

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("")
  const [emailError, setemailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [passwordTogle, setPasswordTogle] = useState(false);
  const [checked, setChecked] = useState(false);
  const [lodder, setLodder] = useState(false);

  const emailRegex =
    /^[A-Z0-9_'%=+!`#~$*?^{}&|-]+([\.][A-Z0-9_'%=+!`#~$*?^{}&|-]+)*@[A-Z0-9-]+(\.[A-Z0-9-]+)+$/i;

  const submit = () => {
    if (email === "" || email === "undefined") {
      setemailError("Email must be required!");
    } else if (emailRegex.test(email) === false) {
      setemailError("Invalid email!");
    } else {
      setemailError("");
    }
    if (password === "" || password === undefined) {
      setPasswordError("Password must be required!");
    } else if (password.length < 8) {
      setPasswordError("Password too small. must be atleast 8 characters");
    } else {
      setPasswordError("");
    }

    if (checked) {
      localStorage.setItem("jobApp-email", email); localStorage.setItem("jobApp-password", password)
    }
    else {
      localStorage.setItem("jobApp-email", ""); localStorage.setItem("jobApp-password", "")
    }

    if (email !== "" && password !== "") {
      setLodder(true);
      authAPIObject.login(
        {
          email,
          password,
          loginDevice: "WEB",
          loginType: "EMAIL",
          deviceToken: "abcdefghijklmnopqrstuvwxyz",
        },
        (result) => {
          setLodder(false);

          if (result.data.status === "false" || result.data.status === false) {
            return Swal.fire({
              icon: "error",
              title: `${result?.data.msg}`,
              timer: 2500,
              showConfirmButton: false,
            });
          }
          if (result?.data?.data?.data?.isEmailVerified) {
            localStorage.setItem("auth-token", result?.data?.data.token);
            Swal.fire({
              icon: "success",
              title: "",
              text: "Logged in successfully.",
              timer: 2500,
              showConfirmButton: false,
            });
            return navigate("/home");
          }

          navigate("/user/email/sent/", {
            state: {
              userId: result?.data?.data.data._id,
              email: result?.data?.data.data.email,
            },
          });
        }
      );
    }
  };


  useEffect(() => {
    setemailError("");
  }, [email]);
  useEffect(() => {
    setPasswordError("");
  }, [password]);

  useEffect(() => {
    const jobEmail = localStorage.getItem("jobApp-email");
    jobEmail && setEmail(jobEmail);

    const jobPassword = localStorage.getItem("jobApp-password");
    jobPassword && setPassword(jobPassword);

  }, [])

  return (
    <>
      <div className="login-card">
        <div className="auth-cart">
          <div className="auth-header">
            <img
              src="./assets/app-logo.png"
              alt="loading..."
              height="100px"
              width="300px"
            />
            <h1>Welcome back</h1>
          </div>
          {/* <h6 className="error-msg">{message}</h6> */}
          <div className="input-cart">
            <div className="input-div">
              <input
                className="input-tag"
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <p className="error-text">{emailError}</p>
            </div>
            <div className="input-div">
              <div className="Password-div">
                <input
                  className="password-tag"
                  type={passwordTogle ? "text" : "password"}
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                {passwordTogle ? (
                  <VisibilityOffIcon
                    className="password-eye"
                    onClick={() => setPasswordTogle(false)}
                  />
                ) : (
                  <VisibilityIcon
                    className="password-eye"
                    onClick={() => setPasswordTogle(true)}
                  />
                )}
              </div>
              <p className="error-text">{passwordError}</p>
            </div>
          </div>
          <div className="forget-div">
            <div className="checkbox-div">
              <Checkbox
                checked={checked}
                onChange={() => setChecked(!checked)}
                inputProps={{ "aria-label": "controlled" }}
                size="small"
                sx={{
                  color: ' #1D3250',
                  '&.Mui-checked': {
                    color: ' #1D3250',
                  },
                }} />

              <p>Remember me</p>
            </div>
            <Link to="/auth/forgetpassword"><p style={{ cursor: 'pointer', color: ' #1D3250' }} >Forgot Password?</p></Link>
          </div>
          <div className="subbmit-button">
            {lodder ? (
              <Box sx={{ alignItems: "center" }}>
                <CircularProgress sx={{ color: " #1D3250", size: "10px" }} />
              </Box>
            ) : (
              <button
                type="button"
                onClick={() => {
                  submit();
                }}
              >
                Sign-In
              </button>
            )}
          </div>
          <div className="bottam-text" >
            <p>
              Don’t have an account? <label onClick={() => { navigate('/auth/signup/') }}>Sign-up</label>
            </p>
          </div>
        </div>
      </div>
    </>

  );
};

export default Login;
