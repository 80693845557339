import React from "react";
import Swal from "sweetalert2";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import subScriptionAPIObject from "../helper/api/subscribtion.api";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
const PlanCard = ({ data, lodder, setLodder }) => {
    const navigate = useNavigate();
    const { PlanType, Price, buttonText, planId, priceInform, list } = data;

    const creatSubcription = () => {
        setLodder(true);
        const token = localStorage.getItem("auth-token");
        if (!token) {
            setLodder(false)
            return Swal.fire({
                icon: 'info',
                title: "",
                text: "Create an account!",
                showConfirmButton: true,
            }).then((result) => {
                if (result.isConfirmed) {
                    setLodder(false);
                    return navigate('/auth/login')
                } else if (result.isDenied) {
                    return null
                }
            })
        }

        subScriptionAPIObject.getActivePlan(token, (result) => {
            if (!result) {
                setLodder(false);
                Swal.fire({
                    icon: "error",
                    title: "Something went wrong, please try again",
                    text: result?.data?.msg,
                    timer: 2500,
                    showConfirmButton: false,
                });
                return null;

            }
            if (result?.data?.status === "false" || result?.data?.status === false) {

                if (result?.data?.msg === "Invalid link" || result?.data?.msg === 'Link expired') {
                    setLodder(false);
                    Swal.fire({
                        icon: "info",
                        title: "",
                        text: "Logged in session has expired. Please log in again",
                        timer: 2500,
                        showConfirmButton: false,
                    });
                    localStorage.clear();
                    window.location.reload(true)
                    return navigate('/auth/login')
                }

                setLodder(false);
                Swal.fire({
                    icon: "error",
                    title: "",
                    text: result?.data?.msg,
                    timer: 2500,
                    showConfirmButton: false,
                });
                return null;
            }

            if (result?.data?.data === null) {
                if (planId === "free_trial_id") {
                    return subScriptionAPIObject.subscription(token, {
                        cardToken: 'd3i04u84384u893u9u2349u28u',
                        planId
                    }, async (res) => {
                        if (res?.data?.status) {
                            Swal.fire({
                                icon: "success",
                                title: "",
                                text: "Free trial subscribed successful",
                                timer: 2500,
                                showConfirmButton: false,
                            });
                            return navigate('/home/')
                        } else {
                            return Swal.fire({
                                icon: "error",
                                title: "",
                                text: "Something went wrong!, please try again.",
                                timer: 2500,
                                showConfirmButton: false,
                            });
                        }
                    })
                } else {
                    setLodder(false);
                    return navigate("/home/pricing/payment/4ujduej", { state: { planId, currentPlan: false, currentPlanId: '' } });
                }
            }

            if (result?.data?.data?.planId === planId) {
                setLodder(false)
                return Swal.fire({
                    icon: 'info',
                    title: "",
                    text: "Already subscribed this plan.",
                    showConfirmButton: true,
                })
            }

            setLodder(false)
            return Swal.fire({
                title: 'Do you want to the change subscription Tier?',
                text: "If you change the subscription tier, your current subscription will be canceled!",
                showDenyButton: true,
                showCancelButton: true,
                confirmButtonText: 'Yes',
                denyButtonText: `No`,
            }).then((res) => {
                if (res.isConfirmed) {
                    return navigate("/home/pricing/payment/4ujduej", { state: { planId, currentPlan: true, currentPlanId: result?.data?.data.planId } });

                } else if (res.isDenied) {
                    return null;
                }
            })
        });

    }



    return (
        <div className='planList-col'>
            <div className='col-heading'>
                <h1>{PlanType}</h1>
                <p className="cardPrice">{Price}<span style={{ fontSize: '16px', color: '#46413E', fontWeight: 'bold' }} >/month</span></p>
                <p style={{ fontSize: '14px' }} >{priceInform}</p>
            </div>
            <div className='col-btn'>
                {
                    lodder
                        ?
                        <Box > <CircularProgress sx={{ color: " #1D3250" }} /></Box>
                        :
                        <button onClick={() => { creatSubcription() }}  >{buttonText}</button>
                }
            </div>
            <div className='col-list'>
                {
                    list.map((item, index) =>
                        <div key={index} className='col-item'>
                            <CheckCircleIcon className="col-icon" />
                            <p>{item}</p>
                        </div>
                    )
                }
            </div>
        </div>
    )
}

export default PlanCard;


