import { useEffect, useState } from "react";
import './home.css';
import { Outlet, useNavigate } from 'react-router-dom';
import Sidebar from "../../sidebar/sidebar";
import Footer from "../../footer/footer";
import userAPIObject from "../../helper/api/user.api";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Swal from "sweetalert2";

function Home() {
    const [navVisible, showNavbar] = useState(true);
    const navigate = useNavigate();
    const [name, setName] = useState("A");

    useEffect(() => {
        getUser()
    }, [])

    const getUser = () => {
        const token = localStorage.getItem("auth-token");
        userAPIObject.getUser(token, (result) => {
            if (result?.data?.status === "false" || result?.data?.status === false) {
                if (result?.data?.msg === "Invalid link" || result?.data?.msg === 'Link expired') {
                    Swal.fire({
                        icon: "info",
                        title: "",
                        text: "Logged in session has expired. Please log in again",
                        timer: 2500,
                        showConfirmButton: false,
                    });
                    localStorage.clear();
                    window.location.reload(true)
                    return navigate('/auth/login')
                }
                return Swal.fire({
                    icon: "error",
                    title: "",
                    text: result?.data?.msg,
                    timer: 2500,
                    showConfirmButton: false,
                });
            }

            setName(result?.data?.data?.fullName)
        });
    }

    return (
        <div className="value-container">
            <div>
                <Sidebar visible={navVisible} show={showNavbar} name={name} />
            </div>
            <div className={!navVisible ? "page" : "page page-with-navbar"}>
                <div className="back-btn-div">
                    <div className="back-btn" onClick={() => navigate('/')}>
                        <div> <ArrowBackIcon /></div>
                        <span>Home</span>
                    </div>
                </div>
                <Outlet context={[setName]} />
            </div>
            <Footer />
        </div>
    );
}

export default Home;

// import React, { useEffect, useState } from 'react'
// import { Outlet, useNavigate } from 'react-router-dom';
// import Sidebar from '../../sidebar/sidebar';
// import Footer from '../../footer/footer';
// import Popup from '../../popup/popup';
// import Navbar from '../../navbar/navbar';

// import './home.css';

// export default function Home() {
// const navigate = useNavigate();
// const [name, setName] = useState("A");
// const redirtionFun = () => {
//     return navigate("/home/userdetails");
// }

// useEffect(() => {
//     redirtionFun()
// }, [])



//     return (
//         <div>
//             <Navbar />
//             <div className="homeContainer">
//                 <Sidebar name={name} />
//                 <Outlet context={[setName]} />
//             </div>
//             <Footer />
//         </div>


//     )
// }
