import axios from "axios";
import config from "../../../config/config";

let subScriptionAPIObject = {
    getActivePlan: async (token, callback) => {
        axios({
            method: "GET",
            url: `${config.API_URL}/subscription/get`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((response) => {
                return callback(response);
            })
            .catch((err) => {
                return callback(err.response);
            });
    },
    subscription: async (token, data, callback) => {
        axios({
            method: "POST",
            url: `${config.API_URL}/subscription/chekout`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
            data: data,
        })
            .then((response) => {
                return callback(response);
            })
            .catch((err) => {
                return callback(err.response);
            });
    },
    cancelSubscription: async (token, planId, callback) => {
        axios({
            method: "DELETE",
            url: `${config.API_URL}/subscription/delete/${planId}`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((response) => {
                return callback(response);
            })
            .catch((err) => {
                return callback(err.response);
            });
    },
    check3Dsecure: async (token, id, callback) => {
        axios({
            method: "GET",
            url: `${config.API_URL}/subscription/check/${id}`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((response) => {
                return callback(response);
            })
            .catch((err) => {
                return callback(err.response);
            });
    },
    getValidPlan: async (token, callback) => {
        axios({
            method: "GET",
            url: `${config.API_URL}/subscription/getvalidsubscription`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then((response) => { return callback(response); }).catch((err) => { return callback(err.response); });
    },
    eventCount: async (token, data) => {
        return axios({
            method: "POST",
            url: `${config.API_URL}/subscription/eventcount`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
            data: data,
        })
    },
};

export default subScriptionAPIObject;
