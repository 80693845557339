import React, { useEffect, useState } from "react";

import DensityMediumIcon from "@mui/icons-material/DensityMedium";
import "./navbar.css";
import { NavLink, Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";


const Navbar = () => {

  const navigate = useNavigate()
  const [authToken, setAuthToken] = useState("")
  const logout = async () => {

    const result = await Swal.fire({
      icon: "question",
      title: 'Are you sure want to logout?',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Yes',
      denyButtonText: `No`,
    })

    if (result.isConfirmed) {
      localStorage.removeItem("auth-token");
      window.location.reload(true)
      navigate("/");
    }
  };



  const setButton = () => {
    const navlinks = document.querySelector(".home-nav-links");
    navlinks?.classList.toggle("mobile-menu");
  };

  useEffect(() => {
    let token = localStorage.getItem('auth-token');
    token && setAuthToken(token);
  }, [])

  return (
    <>
      <nav className="home-navbar">
        <h1 className="home-logo">JOBGURU</h1>
        <ul className="home-nav-links">
          <NavLink to="/" className={({ isActive }) => isActive ? "active" : "nav-item"}
            onClick={() => { document.querySelector(".home-nav-links").classList.remove("mobile-menu") }} >
            Home</NavLink>
          <NavLink to="/about" className={({ isActive }) => isActive ? "active" : "nav-item"} >About</NavLink>
          <NavLink to="/pricing" className={({ isActive }) => isActive ? "active" : "nav-item"} >Pricing</NavLink>
          <NavLink to="/home/userdetails" className={({ isActive }) => isActive ? "active" : "nav-item"} >My account</NavLink>
          {
            authToken ?
              <span onClick={() => logout()} className="nav-item ctn" >Logout</span>
              :
              <>
                <Link to="/auth/login" className="nav-item nav-ctn" >Sign-In</Link>
                <Link to="/auth/signup" className="nav-item ctn" >Sign-Up</Link>
              </>
          }




        </ul>
        <DensityMediumIcon className="menu-btn" onClick={() => setButton()} />
      </nav>
    </>

  );
};

export default Navbar;

//<NavLink to="/home/userdetails" > <img src="./assets/person/photo1.jpg" alt="" className="topbarImg" /></NavLink>  