
import { Outlet } from 'react-router-dom';
import Navbar from "../../navbar/navbar";
import Footer from "../../footer/footer";

function Main() {
    return (
        <>
            <Navbar />
            <Outlet />
            <Footer />
        </>
    );
}

export default Main;
