const webApp = {
  prompt: {

    INTERVIEW_SUMMARY: `ensure give the details similar to below format and The Job Summary should not exceed 90 words.

[Give the heading of Job Summary ]:
              Create a concise and focused summary of the main requirements and responsibilities of the job. Ensure the summary is brief yet informative. `,

    SKILLS_ABILITIES_AND_TOOLS: `

Skills, Abilities, and Tools:

        Number. [Skill/Ability/Tool Name]: [minimum of 6-8 points for each skill/ability/tool]

                - Purpose: [Explain the purpose of this skill/ability/tool in the job context.]
                - Relevance: [Describe how this skill/ability/tool is relevant to the job requirements.]
                - Usage & Implementation of real-life example: [Describe a specific real-life scenario or task where this skill/ability/tool was utilized. Explain in detail how it was implemented to overcome a particular challenge or improve a process. Clearly outline the steps taken and the significant outcomes achieved. Use engaging language and provide a descriptive example within 100-120 words.]


        Please provide the necessary information based on the job details.`,

    ESSENTIAL_SKILLS_AND_ABILITIES: `
               Long-Term Career Progression:
               
               Number. [Point]:
                     Explanation: [Explain how focusing on this point will contribute to your career growth]

               Interview Focus Areas: [Include as many as required]
                
               Number. [Name]:
                      - Advice: [Provide advice on how to effectively address and demonstrate proficiency in this focus area]

               Please provide the necessary information based on the job description, and I will assist you further in preparing for your job interview. Remember to start each module and sub-module on a new line.
               `,

    INTERVIEW_READINESS: `
                Give the below details in the same formate:-

                Job Readiness:

                        Position: [Job Title]

                        Comapny:[Name of company If mentioned]
                
                        Location: [Location If mentioned]
                
                        Mode of Hire: [Mode of Hire If mentioned]
                          
                        Responsibilities:
                
                                - [Responsibility Number]
                
                        Requirements:
                
                                - [Requirement Number]

                        Essential Functions:
                               - [point of Essential Function]
                
                        Interview Readiness Checklist:
                
                        If you are preparing for an interview for the [Job Title] position, here are a few things to keep in mind:
                
                                Number. [Name of skill/tools]: [give the details of Interview Readiness based on job details for the interview prepreation].


                        Make sure give the 5-7 points Interview Readiness Checklist and company, location, and mode of here is mentioned don't include them.
                `,
    INTERVIEW_QUESTIONS: `List of  10-15 Potential Interview Questions with Suggested Answers: provide a list of potential interview questions tailored to the job details you provide. For each question, will suggest an answer along with a real-life example that is specific to the position and the user's years of experience. These examples will provide practical illustrations of the questions and answers. make sure give the heading "Potential Interview Questions" in the top.
Additionally, include 5-10 questions that the job seeker can ask the interviewer about their company or job to prepare for the interview.`,

    TASK_GENRATION_1WEEK: `Provide a comprehensive breakdown of tasks to be completed within the specified duration. The breakdown should include a clear summary of the tasks for each day, their objectives, and the expected outcomes.

Duration: [Specify the duration, e.g., 1 week]

Summary:
The project duration is [duration]. Our objective is to [state the overall objective of the project]. The breakdown of tasks will ensure a systematic approach to achieve the desired outcomes. Below is a detailed breakdown of the tasks for each [duration unit].

[Duration Unit] [N]:
 Task [N]: [Write the headline or title of the first task for the day/week/month/hour]
 Objective: [Specify the objective of the task]
 Expected Outcome: [Specify the expected outcome of the task  within 40-50 words]
 Subtask [N]: [Provide a detailed plan for Subtask 1, including specific steps or actions to be taken]
     Step [N]: [Describe the step of Subtask 1 in detail, including any necessary tools or technologies to be used  within 30-40 words]

Please make sure that you can customize the prompt further by specifying the actual duration, job profile, task scope, and any additional details that are relevant to given specific project.

Note: Adjust the number of tasks or subtasks as necessary to fit within the allocated timeframe. If the duration limit is reached and additional tasks or subtasks are still required, adjust the breakdown accordingly to stay within the time limit.Please ensure that the breakdown of tasks includes detailed descriptions, objectives, and expected outcomes for each task and subtask. break down the tasks on a daily basis, considering that there are only 1st to 5th days in first week for the breakdown of tasks..

Please specify the response length of the response and clarify what you mean by "show all day task show Spratly in week.

[max_tokens : 4096]
*Output length might be according to the max_tokens.*`,

    TASK_GENRATION: `
Provide a comprehensive breakdown of tasks to be completed within the specified duration. The breakdown should include a clear summary of the tasks for each day, their objectives, and the expected outcomes.

and here is the format for breakdown the tasks for each day

Week [N] Summary:
[Give the this Week summary and descibe deep what will be done after the completed this week with in 80-100 words.]

Day [N] of week [N]:
   Task [N]: [Write the headline or title of the first task for the day.]
      Objective: [Specify the objective of the task]
      Expected Outcome: [Specify the expected outcome of the task  within 40-50 words]
      Subtask [N]: [Provide a detailed plan for Subtask 1, including specific steps or actions to be taken]
           Step [N]: [Describe the step of Subtask 1 in detail, including any necessary tools or technologies to be used  within 30-40 words]

please make sure that break down the task with in duration value and give 1st week task according to given format and skip the next weeks task and task must be break down step by step, job profile, task scope, and any additional details that are relevant to given specific project.
considering that there are only 5 days in a week for the breakdown of tasks and we need to only show the 1st week task.

[max_tokens : 4096]
*Output length might be according to the max_tokens.*
`,
    TASK_GENRATION_2WEEK:
      `Please provide the tasks for the 2nd week use the given above format. Make sure Include a summary of the 2nd  week and ensure that the tasks should start from day 1 and end on day 5.`,

    TASK_GENRATION_3WEEK:
      `Please provide the tasks for the 3rd week use the given format. make sure Include a summary for the 3rd week and the tasks should start from day 1 and end on day 5.`,

      TASK_GENRATION_4WEEK:
      `Please provide the tasks for the 4th week use the given format. make sure Include a summary for the 4th week a tasks, and steps according to given format. the task should start from day 1 and end on day 5.`,
  },
  
};

export default webApp;
