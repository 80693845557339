import './footer.css';

const Footer = () => {
  return (
    <footer className="footer-card">
      <div className="footer-list">
        <a href="#">
          <img alt="Loading..." className="social-img" src="./assets/instagram-1.png" />
        </a>
        <a href="#">
          <img alt="Loading..." className="social-img" src="./assets/linkedin-1.png" />
        </a>
        <a href="#">
          <img alt="Loading..." className="social-img" src="./assets/facebook-1.png" />
        </a>
        <a href="#">
          <img alt="Loading..." className="social-img" src="./assets/twitter-1.png" />
        </a>
      </div>
      <div className="footer-line"></div>
      <div className="footer-bottom">
        <span>© 2023 jobguru.ai</span>
      </div>
    </footer>
  )
}

export default Footer;

// import { Link } from "react-router-dom";
// import './footer.css';

// const Footer = () => {
//   return (
//     <footer className="footer-card">
//       <div className="footer-list">
//         <div className="Link-div">
//           <Link to="/">Resources</Link>
//         </div>
//         <div className="Link-div">
//           <Link to="/about">About</Link>
//         </div>
//         <div className="Link-div">
//           <Link to="/">Legal</Link>
//         </div>
//         <div className="Link-div">
//           <Link to="/">FAQs</Link>
//         </div>
//         <div className="Link-div">
//           <Link to="/">Our mission</Link>
//         </div>
//         <div className="Link-div">
//           <Link to="/">Privacy policy</Link>
//         </div>
//         <div className="Link-div">
//           <Link to="/">Tutorials</Link>
//         </div>
//         <div className="Link-div">
//           <Link to="/">Meet the team</Link>
//         </div>
//         <div className="Link-div">
//           <Link to="/">Terms of service</Link>
//         </div>
//         <div className="Link-div">
//           <Link to="/">Contact</Link>
//         </div>
//         <div className="Link-div">
//           <Link to="/">Join us</Link>
//         </div>
//         <div className="Link-div">
//           <Link to="/">cookies</Link>
//         </div>
//       </div>
//       <div className="footer-line"></div>
//       <div className="footer-bottom">
//         <h4>2022 - 2023 JobGuru</h4>
//         <div className="footer-img">
// <a href="#">
//   <img alt="Loading..." className="social-img"  src="./assets/instagram.png" />
// </a>
// <a href="#">
//   <img alt="Loading..." className="social-img"  src="./assets/facebook.png" />
// </a>
// <a href="#">
//   <img alt="Loading..." className="social-img"  src="./assets/twitter.png" />
// </a>
//         </div>
//       </div>
//     </footer>
//   )
// }


// export default Footer;