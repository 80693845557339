import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import CircularProgress from "@mui/material/CircularProgress";
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import Box from "@mui/material/Box";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import authAPIObject from "../../../helper/api/auth.api";
import Swal from "sweetalert2";

const UpdatePassword = () => {
    const [searchParams] = useSearchParams();
    const [password, setPassword] = useState("")
    const [userId, setUserId] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [confirmPasswordError, setConfirmPasswordError] = useState("");
    const [passwordTogle, setPasswordTogle] = useState(false);
    const [confirmPasswordTogle, setConfirmPasswordTogle] = useState(false);
    const [isPasswordUpdated, setIsPasswordUpdated] = useState(false);
    const [message, setMessage] = useState(""),
        [lodder, setLodder] = useState(false),
        [isVerified, setIsVerified] = useState(null);

    const emailVerifyFunction = () => {
        authAPIObject.forgetemailverify(searchParams.get("token"), (result) => {
            setTimeout(() => {
                if (result.data.status === "false" || result.data.status === false) {
                    setIsVerified(false);
                    return setMessage(result.data.msg);
                }
                setUserId(result.data?.data?.userId)
                setIsVerified(true);
            }, [3000]);
        });
    };

    useEffect(() => {
        emailVerifyFunction();
    }, []);

    const submit = () => {
        if (password === "" || password === undefined) {
            setPasswordError("Password must be required!");
        } else if (password.length < 8) {
            setPasswordError("Password too small. must be atleast 8 characters");
        } else {
            setPasswordError("");
        }
        if (confirmPassword === "" || confirmPassword === undefined) {
            setConfirmPasswordError("Confirm password must be required!");
        } else if (confirmPassword !== password) {
            setConfirmPasswordError("Password and confirm password must be same!");
        } else {
            setConfirmPasswordError("")
        }
        if (confirmPassword !== "" && password !== "") {
            setLodder(true);
            authAPIObject.updatePassword({ userId, password },
                (result) => {
                    setLodder(false);
                    if (result.data.status === "false" || result.data.status === false) {
                        return Swal.fire({
                            icon: "error",
                            title: `${result?.data.msg}`,
                            timer: 2500,
                            showConfirmButton: false,
                        });
                    }
                    setIsPasswordUpdated(true);
                }
            );
        }
    };


    useEffect(() => {
        setConfirmPasswordError("");
    }, [confirmPassword]);
    useEffect(() => {
        setPasswordError("");
    }, [password]);


    return isVerified === null ? (
        <div className="verify-card">
            <Box sx={{ display: "flex", margin: "20px 00px" }}>
                <CircularProgress sx={{ color: "#1D3250" }} />
            </Box>
            <h2 className="verify-text">We will be there in a few seconds...</h2>
        </div>
    ) : isVerified ? (<>
        {
            !isPasswordUpdated ?
                <div className="login-card">
                    <div className="auth-cart">
                        <div className="auth-header">
                            <img
                                src="./assets/app-logo.png"
                                alt="loading..."
                                height="100px"
                                width="300px"
                            />
                            <h1>Create New Password</h1>
                        </div>
                        <h6 className="error-msg">{message}</h6>
                        <div className="input-cart">
                            <div className="input-div">
                                <div className="Password-div">
                                    <input
                                        className="password-tag"
                                        type={passwordTogle ? "text" : "password"}
                                        placeholder="New Password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                    {passwordTogle ? (
                                        <VisibilityOffIcon
                                            className="password-eye"
                                            onClick={() => setPasswordTogle(false)}
                                        />
                                    ) : (
                                        <VisibilityIcon
                                            className="password-eye"
                                            onClick={() => setPasswordTogle(true)}
                                        />
                                    )}
                                </div>
                                <p className="error-text">{passwordError}</p>
                            </div>
                            <div className="input-div">
                                <div className="Password-div">
                                    <input
                                        className="password-tag"
                                        type={confirmPasswordTogle ? "text" : "password"}
                                        placeholder="Confirm password"
                                        value={confirmPassword}
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                    />
                                    {confirmPasswordTogle ? (
                                        <VisibilityOffIcon
                                            className="password-eye"
                                            onClick={() => setConfirmPasswordTogle(false)}
                                        />
                                    ) : (
                                        <VisibilityIcon
                                            className="password-eye"
                                            onClick={() => setConfirmPasswordTogle(true)}
                                        />
                                    )}
                                </div>
                                <p className="error-text">{confirmPasswordError}</p>
                            </div>

                        </div>

                        <div className="subbmit-button" style={{ marginTop: '20px' }}>
                            {lodder ? (
                                <Box sx={{ alignItems: "center" }}>
                                    <CircularProgress sx={{ color: "#1D3250", size: "10px" }} />
                                </Box>
                            ) : (
                                <button
                                    type="button"
                                    onClick={() => {
                                        submit();
                                    }}
                                >
                                    Save
                                </button>
                            )}
                        </div>
                    </div>
                </div> :
                <div className="verify-card">
                    <div className="main-card">
                        <CheckCircleOutlinedIcon sx={{ color: 'white' }} fontSize="large" />
                        <h1  >Password successfully updated.</h1>
                        <h2 className="verify-text" style={{ margin: '20px auto' }}> Back to your Sign in window.</h2>
                        <Link to="/auth/login" className="up-ctn"  >Back</Link>
                    </div>
                    <div className="bottom-text">
                        <p>Self-custodial verify by</p>
                        <p>JobGuru.ai</p>
                    </div>
                </div>}
    </>

    ) :
        <div className="verify-card">
            <div className="main-card">
                <h1 className="error-msg" >{message}</h1>
                <ErrorOutlineIcon sx={{ color: 'white' }} fontSize="large" />
                <h2 className="verify-text">
                    Return to original window to request for a new link
                </h2>
                <h2 className="verify-text">You can close this window</h2>
            </div>
            <div className="bottom-text">
                <p>Self-custodial verify by</p>
                <p>JobGuru.ai</p>
            </div>
        </div>;
};

export default UpdatePassword;
